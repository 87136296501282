import axios from "axios"
import { config } from "../../config"


const createModification = (subscriptionId, priceId, issuerId) => {
    return new Promise((resolve, reject) => {

        axios.post(`${config.ERISED_BACKEND_URL}/modifications/createModification`, {
            data: {
                subscriptionId: subscriptionId,  
                priceId: priceId,
                issuerId: issuerId
            }
        })
        .then(res => {
            resolve(res?.data?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}


export {
    createModification
};
