import {
    LinkAuthenticationElement
  } from "@stripe/react-stripe-js";
  import { useStripe, useElements } from "@stripe/react-stripe-js";
  import { useContext, useState } from "react";
  import styled from "styled-components";
  import { useTranslation } from "react-i18next";
  import { CartContext } from "../../../context/CartContext";
  import { AlertContext, Alert_, Focus_,  Main_Loader, Main_Text_Loader} from "monica-alexandria";
  import {create_confirm_subscription } from "../../../services/api/Subscriptions";
  import store from "../../../redux/store";
  import i18n from "../../../localization/i18next";
  import { useNavigate } from "react-router-dom";
  const clients = require('../../../config/index').config

const PaymentNoCard = styled.div``;

export default function Payment_No_Card(props) {

  const cart  = useContext(CartContext);
  const { t } = useTranslation(); 
  const navigate = useNavigate();
  const { alerts, setAlerts } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] =  useState(false)
  const customer = window.sessionStorage.getItem("customer");
  const user = store.getState().user.user;
  const currentCart = cart.length>1 ? cart : store.getState().cart
  const lang = i18n.language ? i18n.language : "el";
  const quest = store.getState().params.quest;

  const chooseRedirectURI = (category, property) => {
    switch (true) {
      case category === 'lois':
        return `${clients['lois']}/${lang}/success-payment`;
      case category === 'bond' && property === "vandador":
        return `${clients['vandador']}/${lang}/more-info?email=${email}&plan=${currentCart[0]?.name}`;
      case category === 'bond' && property === "agency":
        return `${clients['agency']}/${lang}/more-info?email=${email}&plan=${currentCart[0]?.name}`;
      case category === 'dailyprofit':
        return `${clients['dailyprofit']}/${lang}/success-payment`;
      default:
        return `${clients['griphook']}/${lang}/my-plans`;
    }
  }
  

  const alertMessage = (message, status) => {
    
    setAlerts([
        ...alerts,
        {
            message: {
                text: message,
                status: status,
            },
        },
    ]);
    setTimeout(() => {
      setAlerts((prevAlerts) => prevAlerts.slice(1));
    }, 5000);
  };


  const createAndConfirmSubscritpion = () =>{

    create_confirm_subscription(customer, currentCart, props?.trial, user?.sub, email, false, quest, props?.discount)
    .then(res=>{
      if(res?.code==='SCC4000') {
        setLoading(false);
        alertMessage(t(`Please fill in your email`), 'Error');
      }
      else {
        setLoading(true);
        handleServerResponse(res?.data)
      } 
    })

  }

  const handleServerResponse = async (response) => {

        window.sessionStorage.removeItem('billing');
        window.sessionStorage.removeItem('customer');
        window.location.replace(chooseRedirectURI(currentCart[0]?.category, currentCart[0]?.property))
        window.localStorage.removeItem('cart');
}

  return (
    <PaymentNoCard>
      {loading &&  <Main_Text_Loader text={t('We are proccesing your information. Please do not leave the page.')}/>}
        <LinkAuthenticationElement  onChange={(e) => setEmail(e?.value?.email)}/> 
        <Focus_ text={t("Claim you free trial")} iconRight="Next"  onClick={() => createAndConfirmSubscritpion()}/>
    </PaymentNoCard>
  );
}
