import { Grey_Link, PageAlwaysVisible, PageDesktopPadding, PageForty, PageSubtitle, PageTitle } from 'monica-alexandria'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Subscribe } from '../../styles/Layout.css'
import Coupon_ from '../../components/billing/Coupon_'
import Annual_ from '../../components/billing/Annual_'
import Lois_Plan from './Lois_Plan'
import Billing_ from '../../components/billing/Billing_'
import No_Card from '../../components/billing/No_Card'

export default function FIRST() { 

  const {t} = useTranslation()
  const [trial, setTrial] = useState(true)
  const [coupon, setCoupon] = useState('');
  const customer = window?.sessionStorage?.getItem('customer')

  return (
    <Subscribe>
      <Helmet>Doitforme | Adsnap subscribe</Helmet>
      <PageAlwaysVisible>
        <PageTitle>
          <Grey_Link iconLeft="Back" noPadding size="large" />
          <h1>{t("Get started with Adsnap")}</h1>
        </PageTitle>
        <PageSubtitle>
          <h3>{t("Start a 15-day")} Pro plan {t("trial")}</h3>
        </PageSubtitle>
        {/* <Annual_/> */}
        <Lois_Plan />
        <Coupon_ discount={coupon} setDiscount={setCoupon} trial={trial} setTrial={setTrial} customer={customer}/>
      </PageAlwaysVisible>
      <PageAlwaysVisible>
        <PageDesktopPadding>
          {/* <Billing_ /> */}
          <No_Card />
        </PageDesktopPadding>
      </PageAlwaysVisible>
    </Subscribe>
  )
}
