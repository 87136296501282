import { toggleSubscription } from "../../../../services/api/Subscriptions";
import { getTransactions } from "../../../../services/api/Transactions";

export const fetchTransactions = (input, setTransactions) => {
  input = encodeURIComponent(JSON.stringify(input));

  getTransactions(input)
    .then((res) => {
      setTransactions(res);
    })
    .catch((err) => {});
};

export const toggleSubscriptionStatus = (subscriptionId, action) => {
  toggleSubscription(subscriptionId, action)
    .then((res) => {})
    .catch((err) => {});
};

export const construct_subscription_from_last_transaction = (
  transactions,
  plans,
  subscription,
  setSubcription
) => {
  
  const mostRecentTransaction = transactions.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  )[0];

  let matchedPlan;
  for ( let plan of plans ) {
    if ( plan?.priceId === mostRecentTransaction?.priceId ) matchedPlan = plan; 
  }

  setSubcription({
    ...subscription,
    isObjectConstructed: true,
    status: mostRecentTransaction?.status,
    subscriptionId: mostRecentTransaction?.subscriptionId,
    action: mostRecentTransaction?.status !== "active" ? "enable" : "disable",
    amount: mostRecentTransaction?.amount,
    currency: (mostRecentTransaction?.currency).toUpperCase(),
    priceId: mostRecentTransaction?.priceId,
    interval: matchedPlan?.interval,
  });

  return;
};
