import { Alert, AlertContext, Alert_, Grey_Link, Main_Loader, PageAlwaysVisible, PageDesktopPadding, PageForty, PageSubtitle, PageTitle } from 'monica-alexandria'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import styled, { ThemeContext } from 'styled-components'
import { Subscribe } from '../../styles/Layout.css'
import Coupon_ from '../../components/billing/Coupon_'
import Annual_ from '../../components/billing/Annual_'
import Lois_Plan from './components/Lois_Plan'
import Billing_ from '../../components/billing/Billing_'
import No_Card from '../../components/billing/No_Card'
import { CartContext } from '../../context/CartContext'
import { useNavigate } from "react-router-dom";
import { handleCheckoutSession, validateCart, validateFields } from "../checkoutDetails/utils";
import { getProductswithCategoryAsInput } from "../../services/api/Plans";
import { get_customer } from '../../services/api/Subscriptions'
import store from '../../redux/store'
import { get_my_subscriptions } from '../../services/api/Mocks'
import i18n from "../../localization/i18next";

const clients = require('../../config/index').config

export default function LOIS_SUBSCRIBE() { 

  const {t} = useTranslation();
  const { cart, setCart } = useContext(CartContext);
  const { alerts, setAlerts } = useContext(AlertContext);
  const navigate = useNavigate();
  const [trial, setTrial] = useState(true)
  const [coupon, setCoupon] = useState('');
  const [selectedOption, setSelectedOption] = useState('month'); // 'year' or 'month'
  const [isLoading, setIsLoading] = useState(true);
  const [customer, setCustomer] = useState(false);
  const [customerIsLoading, setCustomerIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [defaultItem, setDefaultItem] = useState(false);
  const lang = i18n.language ? i18n.language : "el";

  const [billingFields, setBillingFields] = useState({});

  const user = store.getState().user.user;

  const check_if_customer_exists = () => {
    get_customer(user?.sub, 'lois')
      .then(res => {
        const billingData = {
          name: res?.customer?.name|| `${user?.firstname} ${user?.lastname}`,
          email: res?.customer?.email || user?.username,
          vatNumber: res?.customer?.vat
        };
        console.log('res', customer)
        setCustomer(res?.customer?.customerId)
        window?.sessionStorage?.setItem('customer', res?.customer?.customerId);
        window.sessionStorage.setItem('billing', JSON.stringify(billingData));
        setBillingFields(billingData); // Update billingFields state with the retrieved billing data
        setCustomerIsLoading(false);
      })
      .catch(err => console.log(err));
  }

  // Function to initialize cart and set default item
  const initializeCartAndDefaultItem = (res) => {
    console.log('CART INIT', res)

    if(res?.length>1) {
      const item = res?.find((item) => item?.property === "base");
      const sources = res?.filter(obj => obj?.priceId !== item?.priceId);
      setDefaultItem(item);
      setProducts(sources);
      setIsLoading(false);
    }
    else {
      sessionStorage.removeItem('cart');
      setCart([])
      setDefaultItem(res[0]);
      setProducts([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Fetch products only once and call the function to initialize cart and set default item
  getProductswithCategoryAsInput({category: 'lois'}, selectedOption)
    .then(res => initializeCartAndDefaultItem(res))
    .catch((err) => console.log(err));
        // Retrieve cart from sessionStorage if available
  store.dispatch({type:'SAVE_CART_PARAMS', payload: {category:'lois', interval:'month'}});

}, []);
  // Update sessionStorage whenever the cart changes
  useEffect(() => {
    // Only set the default item to cart if it's not already in the cart
    if (defaultItem && !cart.some((item) => item.priceId === defaultItem.priceId)) {
      setCart([defaultItem]); // Use callback form to ensure up-to-date cart
      setIsLoading(false);
    }        
    store.dispatch({type:'SAVE_CART', payload: cart});

  }, [defaultItem, cart]);

  useEffect(() => {
    if(user?.sub) {
      get_my_subscriptions(user.sub)
        .then(data => {
          let lois_subs=data?.data.find((sub) => sub?.category==='lois')
          console.log("lois_sub", lois_subs)
          if(lois_subs?.tools?.some(tool => tool?.active)) {
            window.location.replace(`${clients['lois']}/${lang}/welcome`)
          } 
          else {
            if(!customer){
              check_if_customer_exists()
            } 
          }
        })
        .catch(err => {})
    }
  }, [])
  
  console.log('billing', billingFields)
  console.log('customer', customer)
  console.log('isLoading', isLoading)
  console.log('customerIsLoading', customerIsLoading)
  console.log('cart?.length==0', cart?.length==0)

  if(isLoading && customerIsLoading && cart?.length==0) return <Main_Loader/> 
  else return (
    <Subscribe>
      <Alert_ messages={alerts} /> 
      <Helmet>
        <title>Doitforme | Adsnap subscribe</title>
      </Helmet>
      <PageAlwaysVisible>
        <PageTitle>
          <Grey_Link iconLeft="Back" noPadding size="large" onClick={()=> navigate(-1)} />
          <h1>{t("Get started with Adsnap")}</h1>
        </PageTitle>
        <PageSubtitle>
          {trial ? <h3>{t(`Start a ${coupon?.couponInfo?.trialDays}-day`)} Pro plan {t("trial")}</h3> : <h3>Pro plan</h3> }
        </PageSubtitle>
        {/* <Annual_/> */}
        <Lois_Plan /> 
        {!customerIsLoading && <Coupon_ customer={customer} discount={coupon} setDiscount={setCoupon} trial={trial} setTrial={setTrial}/>}
      </PageAlwaysVisible>
      <PageAlwaysVisible>
        <PageDesktopPadding>
         {coupon?.couponInfo?.noCard==='true' && trial ? 
          <No_Card customer={customer} daysOff={coupon?.couponInfo?.trialDays} discount={coupon?.couponId} trial={trial} total={cart[0]?.amount} currency={cart[0]?.currency}/> : <Billing_  discount={coupon} trial={trial} billingFields={billingFields}/> }
        </PageDesktopPadding>
      </PageAlwaysVisible>
    </Subscribe>
  )
}
