import { PageForty } from 'monica-alexandria'
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { get_plans } from '../../services/api/Mocks';

export default function TEST_PAYMENT() {


    const [data, setData] = useState({
        userId: 'gkchris',
        product_priceId: 'price_1LQ9RaHtvblElzJx8Quq1oKD',
        subscription: {
            "price": null,
            "purchaseDate": "2021-11-17T10:54:21.048Z",
            "expirationDate": "2023-09-27T09:54:21.000Z",
            "userId": "c58ff525-1ca6-4e1d-9f97-20059af00a6a",
            "profileId": "5d89dd3bc602cb7428367e66",
            "duration": "31449600000",
            "typeOfItem": "profile",
            "bundle": "Standard",
            "title": "Ηρακλής Τσιτλακίδης",
            "phone": "6955000909",
            "friendly_name": "",
            "holder_name": "",
            "cardDate": "",
            "unboxed": true,
            "active": false
        },
        business: {
            city: 'Athens',
            job: 'Panzerr',
            lat: 55.55,
            lng: 55.55
        }
    })


    const test = () => {
        axios.post(`http://localhost:52000/temps/tempPost`, {
            userId: data?.userId,
            product_priceId: data?.product_priceId,
            subscription: data?.subscription,
            business: data?.business
        })
    }

     
    return (
        <PageForty>
            <h1>PAYMENT TEST PAGE</h1>
            <button onClick={() => test()}>Do test</button>
            <div></div>
        </PageForty>
    )
}

