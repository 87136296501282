import { PageForty } from 'monica-alexandria'
import React, { useEffect, useState } from 'react'
import mqttService from '../../services/websockets/mqttService';
import store from '../../redux/store'
import { useNavigate } from 'react-router-dom';

export default function TEST_MQTT() {

    const lang = window?.location?.pathname ? window?.location?.pathname.split("/")[1] : 'el';

    const navigate = useNavigate();

    const user = store.getState().user?.user ? store.getState().user?.user : 'Guest'
    const userId = user ? user.sub : false;

    const [client, setClient] = useState(mqttService.getClient())
    const topic = userId ? `/channel/checkout/${userId}` : false;
    const [message, setMessage] = useState();
    
    const messageHandler = async () => {
        let msg = await mqttService.onMessage(client, user);
        setMessage(msg)
    } 

    useEffect(() => {
        mqttService.subscribe(client, topic, user);
        messageHandler()
        return () => {
            mqttService.unsubscribe(client, topic)
            mqttService.closeConnection(client)
        }
    },[])

    useEffect(() => {
        if ( message ) {
            navigate(`/${lang}${message}`)
        }
    },[message])
     
    return (
        <PageForty>
            <h1>MQTT TEST PAGE</h1>
            <h2>MQTT Message: {message ? message : 'No message'}</h2>
        </PageForty>
    )
}

