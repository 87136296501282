import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Microservice from "./router/Router";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloakConfig from "./services/authentication/Keycloak";
import { jwtReqInterceptor, jwtResInterceptor } from "./helpers/interceptor";
import "./localization/i18next";
import { Provider } from "react-redux";
import store from "./redux/store";
import { I18nextProvider } from "react-i18next";
import i18n from "./localization/i18next";

let options = { onLoad: "check-sso", checkLoginIframe: false };

const root = ReactDOM.createRoot(document.getElementById("root"));

const authentication = process.env.REACT_APP_AUTHENTICATION;

jwtReqInterceptor();
jwtResInterceptor();

if (authentication == "ON") {
  root.render(
    <ReactKeycloakProvider
      initOptions={options}
      authClient={keycloakConfig}
      LoadingComponent={<div></div>}
    >
      <Provider store={store}>
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <Microservice />
          </I18nextProvider>
        </BrowserRouter>
      </Provider>
    </ReactKeycloakProvider>
  );
} else {
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <Microservice />
        </I18nextProvider>
      </BrowserRouter>
    </Provider>
  );
}
