import axios from "axios"
import { config } from "../../config"


const getProductswithCategory = (priceId) => {
    return new Promise((resolve, reject) => {

        axios.get(`${config.GRIPHOOK_BACKEND_URL}/plans/getProductswithCategory?priceId=${priceId}`)
        .then(res => {
            resolve(res?.data?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const getProductswithCategoryAsInput = (key, interval) => {

    return new Promise((resolve, reject) => {

        if (interval) key.interval = interval;

        console.log(key);

        axios.get(`${config.GRIPHOOK_BACKEND_URL}/plans/getProductswithCategory?params=${JSON.stringify(key)}`)
            .then(res => {
                resolve(res?.data?.data);
            })
            .catch(() => {
                resolve(false);
            });
    });
}


const getProduct = (name) =>{
    return new Promise((resolve, reject)=>{
        axios.get(`${config.GRIPHOOK_BACKEND_URL}/plans/getPriceIdByName?plan=${name}`)
        .then(res=>{
            resolve(res?.data?.data)
        })
        .catch(()=>{
            resolve(false);
        })

    })
}

const create_plan = (type, amount, recurring) =>{
    return new Promise((resolve, reject) => {
        axios.post(`${config.GRIPHOOK_BACKEND_URL}/plans/createProduct`,{
            type: type,               
            amount: amount,
            recurring: recurring
        })
        .then(res=> {
            resolve(res)
        })
        .catch(err=>{
            resolve(err)
        })
    })
}

const get_plans = () => {

    return new Promise((resolve, reject) => {
        axios.get(`${config.GRIPHOOK_BACKEND_URL}/plans/displayActiveProducts`, {
            headers:{"x-griphook-secret": "HH{T~Cw=p5ng6:gW[~v+YhtL~9y_+ec4^'NGR{(>YtR%8f$/j93rerwBsB9"}
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}

export {
    getProductswithCategory,
    getProductswithCategoryAsInput,
    getProduct,
    create_plan,
    get_plans
};
