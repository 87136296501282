import { logo, On_Click_Card, ThemeContext } from 'monica-alexandria'
import React, { useContext } from 'react'
import styled from 'styled-components'

const MyPlansPocketAgency = styled.div`
  position: relative;

  .Children{
    margin-top: 0;
    padding-top: 0;
  }
`;

const MyPlansPocketAgencyFooter = styled.div``

const MyPlansPocketAgencyAmount = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;

  h5{
    font-size: 3rem;
  }

  h5,h6{
    white-space: nowrap;
  }
`

const MyPlansPocketAgencyStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  aside{
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background: ${p => p.status === 'trialing' ? 'var(--focus)' :
      p.status === 'active' ? 'var(--success)' :
      'var(--error)' 
    };
  }

  h5{
    color: ${p => p.status === 'trialing' ? 'var(--focus)' :
      p.status === 'active' ? 'var(--success)' :
      'var(--error)' 
    };
  }
`;

const MyPlansPocketAgencyPlan = styled.div`
  position: absolute;
  left: 50%;
  top: 18rem;
  transform: translateX(-50%);

  h5{
    white-space: nowrap;  
    color: var(--greyDark);
    opacity: .5;
  }
`

const MyPlansPocketAgencyInactive = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
  background: ${p => p.theme.background};
  opacity: .6;
  z-index: 2;
  cursor: pointer;
`


export default function My_Plans_PocketAgency(props) {

  const {theme} = useContext(ThemeContext);

  return (
    <MyPlansPocketAgency>
      {props.active ?
        null
      :
        <MyPlansPocketAgencyInactive></MyPlansPocketAgencyInactive>
      }
      <On_Click_Card
        imgSmall={logo.bond[theme]}
        title={props.tool}
        onClick={props.onClick}
      >
        <MyPlansPocketAgencyPlan>
          <h5>{props.plan}</h5>
        </MyPlansPocketAgencyPlan>
        <MyPlansPocketAgencyFooter>
          <MyPlansPocketAgencyAmount>
            <h5>{props.amount}</h5>
            <h6>{props.interval}</h6>
          </MyPlansPocketAgencyAmount>
          <MyPlansPocketAgencyStatus status={props.status}>
            <h5>{props.status}</h5>
          </MyPlansPocketAgencyStatus>
        </MyPlansPocketAgencyFooter>
      </On_Click_Card>
    </MyPlansPocketAgency>
  )
}
