import { logo, On_Click_Card, ThemeContext } from 'monica-alexandria'
import React, { useContext } from 'react'
import styled from 'styled-components'

const MyPlansDailyprofit = styled.div`

  .Children{
    margin-top: 0;
    padding-top: 0;
  }
`;

const MyPlansDailyprofitFooter = styled.div``

const MyPlansDailyprofitAmount = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;

  h5{
    font-size: 3rem;
    color: ${p => p.active === true ? 'var(--success)' : 'var(--grey)' };    
  }
`

const MyPlansDailyprofitStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;

  h5{
    color: ${p => p.active === true ? 'var(--success)' : 'var(--grey)' };    
    }
`;



export default function My_Plans_Dailyprofit(props) {

  const {theme} = useContext(ThemeContext);

  return (
    <MyPlansDailyprofit>
      <On_Click_Card
        imgSmall={logo.dailyprofit[theme]}
        title={props.tool}
        subtitle={props.questsNumber}
        onClick={props.onClick}
      >
        <MyPlansDailyprofitFooter>
          <MyPlansDailyprofitAmount active={props.active}>
            <h5>{props.numOfQuests}</h5>
          </MyPlansDailyprofitAmount>
          <MyPlansDailyprofitStatus active={props.active}>
            <h5>{props.text}</h5>
          </MyPlansDailyprofitStatus>
        </MyPlansDailyprofitFooter>
      </On_Click_Card>
    </MyPlansDailyprofit>
  )
}
