import { ThemeContext, logo } from 'monica-alexandria'
import React, { useContext } from 'react'
import styled from 'styled-components'

const LoisCheckout = styled.div`
    padding: 2rem;
    background: ${p => p.theme.low};
    border-radius: var(--smallRadius);
    text-align: center;
    
    img{
        width: 15rem;
        margin-bottom: 2rem;
    }
`

export default function Lois_Checkout(props) {

    const {theme} = useContext(ThemeContext);

  return (
    <LoisCheckout>
        <img src={logo.lois[theme]} />
        <h2>Adsnap</h2>
        <h5>Doitforme</h5>
    </LoisCheckout>
  )
}
