import { IconSvg, device } from "monica-alexandria";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const PaymentFreeTrial = styled.div`
  background: linear-gradient(to bottom right, var(--focus), var(--main));
  border-radius: 2rem;
  position: relative;
  min-height: 11rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  h1,
  p,
  h4 {
    color: var(--white) !important;
  }

  @media ${device.lg} {
    flex-direction: column;
    align-items: normal;
    min-height: none;
  }

  @media ${device.md} {
    align-items: center;
    min-height: 11rem;
    flex-direction: row;
  }

  @media ${device.sm} {
    flex-direction: column;
    align-items: normal;
    min-height: none;
  }
`;

const PaymentFreeTrialSissors = styled.div`
  padding: 2rem 1rem 2rem 3.5rem;
  border-right: 2px dashed var(--white);

  @media ${device.lg} {
    border-right: none;
    border-bottom: 2px dashed var(--white);
    padding: 2rem 3.5rem;
  }

  @media ${device.md} {
    border-bottom: none;
    padding: 2rem 1rem 2rem 3.5rem;
    border-right: 2px dashed var(--white);
  }

  @media ${device.sm} {
    border-right: none;
    border-bottom: 2px dashed var(--white);
    padding: 2rem 3.5rem;
  }
`;

const PaymentFreeTrialPerks = styled.div`
  padding: 2rem 3.5rem 2rem 1rem;

  ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    li {
      display: flex;
      align-items: flex-start;
      gap: 1rem;

      svg {
        width: var(--normalIcon);
        fill: var(--white);
      }
    }
  }

  @media ${device.lg} {
    padding: 2rem 3.5rem;
  }

  @media ${device.md} {
    padding: 2rem 3.5rem 2rem 1rem;
  }

  @media ${device.sm} {
    padding: 2rem 3.5rem;
  }
`;

const PaymentFreeTrialLeft = styled.div`
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  background: ${(p) => p.theme.background};
  position: absolute;
  left: -2rem;
  top: 50%;
  transform: translateY(-50%);

  @media ${device.lg} {
    top: 10rem;
  }

  @media ${device.md} {
    top: 50%;
  }

  @media ${device.sm} {
    top: 10rem;
  }
`;
const PaymentFreeTrialRight = styled.div`
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  background: ${(p) => p.theme.background};
  position: absolute;
  right: -2rem;
  top: 50%;
  transform: translateY(-50%);

  @media ${device.lg} {
    top: 10rem;
  }

  @media ${device.md} {
    top: 50%;
  }

  @media ${device.sm} {
    top: 10rem;
  }
`;

export default function Payment_Free_Trial(props) {
  const { t } = useTranslation();
  return (
    <PaymentFreeTrial>
      <PaymentFreeTrialSissors>
        <h4>{t("Coupon applied successfully")}</h4>
        <h1>{props?.coupon}</h1>
      </PaymentFreeTrialSissors>
      <PaymentFreeTrialPerks>
        <ul>
          <li>
            <IconSvg Icon="Bullet" />
            <p>{t(props?.couponInfo?.couponText)}</p>
          </li>
          <li>
            <IconSvg Icon="Bullet" />
            <p>{t("Cancel any time")}</p>
          </li>
          <li>
            <IconSvg Icon="Bullet" />
            <p>{t("Access all Adsnap sources")}</p>
          </li>
        </ul>
      </PaymentFreeTrialPerks>
      <PaymentFreeTrialLeft></PaymentFreeTrialLeft>
      <PaymentFreeTrialRight></PaymentFreeTrialRight>
    </PaymentFreeTrial>
  );
}
