import { Form } from "monica-alexandria";
import styled from "styled-components";

export const PlansStatus = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 0 2rem 1rem 2rem;
`;

export const PlansSlider = styled.div``;

export const Clipboard = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: var(--normalPads);
  background: ${(p) => p.theme.high};
  border-radius: var(--smallRadius);
 
`;
