import { ThemeContext, logo } from 'monica-alexandria'
import React, { useContext } from 'react'
import styled from 'styled-components'

const LoisPlan = styled.div`
    margin-bottom: 4rem;
    box-shadow: ${p => p.theme.out};
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    padding: 2rem;
    border-radius: var(--smallRadius);
    background: ${p => p.theme.low};

    img{
        height: 10rem;
    }
`

const LoisPlanRight = styled.div`

    h1{

        span{
            font-size: 1.4rem !important;
            font-family: "fontLight";
        }
    }
`

export default function Lois_Plan(props) {

    const {theme} = useContext(ThemeContext);

  return (
    <LoisPlan>
        <img src={logo.lois[theme]} />
        <LoisPlanRight>
            <h2>Doitforme Adsnap</h2>
            <h3>Pro plan</h3>
        </LoisPlanRight>
    </LoisPlan>
  )
}
