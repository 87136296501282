import {
  CardsForty,
  CardsSixty,
  Confirmation_,
  On_Click_Card,
  PageAlwaysVisible,
  PageTitle,
  PageToolsForty,
  Section_Title,
  Slider_,
  Stat_,
  Sub_Status,
  ThemeContext,
  Toggle_Stat,
  logo,
} from "monica-alexandria";
import React, { useContext, useEffect, useState } from "react";
import Toolbar_ from "../../partials/navigation/Toolbar_";
import store from "../../../redux/store";
import { prettifyNumbers, prettifyDates } from "../../../helpers/prettifiers";
import {
  construct_subscription_from_last_transaction,
  fetchTransactions,
  toggleSubscriptionStatus,
} from "./utils";
import { PlansStatus } from "../plans/PLANS.css";
import { t } from "i18next";
import Slider_Plans from "./partials/Slider_Plans";
import { getProductswithCategory } from "../../../services/api/Plans";
import currencies from '../../../data/currencies.json';
import { fetch_cancel_at_period_end } from "../../../services/api/Subscriptions";

export default function SUBSRIPTION() {

  const user = store.getState().user.user;
  const userId = user.userId;

  const { theme } = useContext(ThemeContext);
  const [confirmation, setConfirmation] = useState(false);
  const [slider, setSlider] = useState(false);
  const [activeSlider, setActiveSlider] = useState("");
  const openSlider = (active) => {
    setActiveSlider(active);
    setSlider(true);
  };


  const [changedPlan, setChangedPlan] = useState({
      plan_details: {},
      hasChanged: false
  });

  const [transactions, setTransactions] = useState([]);

  const [plans, setPlans] = useState({
    performedBackendRequest: false,
    all_plans: []
  })

  // Based on the last transaction
  const [subscription, setSubscription] = useState({
    active: false,
    subscriptionId: "",
    action: "",
    cancel_at_period_end: false,
    isObjectConstructed: false,
    hasCheckedCancelAtPeriodEnd: false,
  });

  const fetch_transactions_input = {
    userId: userId,
    category: "bond",
  };

  const fetchPlans = () => {
    const priceId = transactions[0]?.priceId;
    getProductswithCategory(priceId)
    .then((res) => {
      setPlans({
        performedBackendRequest: true,
        all_plans: res
      })
    }).catch(err=>{})
  }

  const isSubAwaitCancelation = () => {
    fetch_cancel_at_period_end(subscription.subscriptionId)
    .then((res) => setSubscription({...subscription, cancel_at_period_end: res, hasCheckedCancelAtPeriodEnd: true}))
    .catch(err=>{})
  }


  const handleActiveText = () => {
    let text;
    subscription?.cancel_at_period_end ? text = (t('Pending cancelation')) : text = subscription?.status;
    return text;
  }

  const handleActiveSwitch = () => {
    let active;
    subscription.status === "active" ? active = true : active = false
    subscription.cancel_at_period_end ? active = false : active = active;
    return active;
  }


  useEffect(() => {
    if ( subscription.cancel_at_period_end ) setSubscription({...subscription, action: 'enable'})
  },[subscription.hasCheckedCancelAtPeriodEnd])

  useEffect(() => {
    if ( subscription.isObjectConstructed && !subscription.hasCheckedCancelAtPeriodEnd ) isSubAwaitCancelation()
  },[subscription.isObjectConstructed])

  useEffect(() => {
    if (transactions.length > 0)
      construct_subscription_from_last_transaction(
        transactions,
        plans.all_plans,
        subscription,
        setSubscription
      );
  }, [plans.all_plans]);

  useEffect(() => {
    if ( transactions.length > 0 && !plans.performedBackendRequest ) {
      fetchPlans()
    }
  },[transactions.length])

  useEffect(() => {
    fetchTransactions(fetch_transactions_input, setTransactions);
  }, []);

  return (
    <PageToolsForty className={slider ? "hideScrollbar" : ""}>
      <Toolbar_ />
      {slider && (
        <Slider_
          onClick={() => setSlider(false)}
          onClose={() => setSlider(false)}
          text={t('Close')}
          icon="Xicon"
          title={t('Plans')}
        >
          {activeSlider === "Plan" ? <Slider_Plans 
                  plans={plans.all_plans} 
                  activePriceId={subscription?.priceId} 
                  confirmation={confirmation}
                  setConfirmation={setConfirmation}
                  setSlider={setSlider}     
                  subscriptionId={subscription?.subscriptionId}
                  priceId={subscription?.priceId} 
                  issuerId={userId}
                  changedPlan={changedPlan}
                  setChangedPlan={setChangedPlan}
          /> : null}
        </Slider_>
      )}
      <PageAlwaysVisible>
        <PageTitle>
          <h1>Adsnap</h1>
        </PageTitle>
        <CardsForty>
          <On_Click_Card
            imgSmall="https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745"
            title={`${user?.firstname} ${user?.lastname}`}
            subtitle={t('click to view')}
          />
          <On_Click_Card
            img={logo.lois[theme]}
            onClick={() => openSlider("Plan")}
            imgBehave="Contain"
            subtitle={`${currencies[subscription?.currency]?.symbol}${subscription?.amount}/${subscription?.interval}`}
          ></On_Click_Card>
        </CardsForty>
        <Section_Title text={t('Subscription status')} />
        {subscription && (
          <Toggle_Stat
            text={handleActiveText()}
            active={handleActiveSwitch()}
            onClick={() =>
              toggleSubscriptionStatus(
                subscription.subscriptionId,
                subscription.action
              )
            }
          />
        )}
        { changedPlan.hasChanged ? 
            <>
              <Section_Title text={t('Extras')} />
              <Stat_ 
                    title={t('Plan has changed')} 
                    icon="AddPlan" 
                    text={`${currencies['EUR']?.symbol}${changedPlan?.plan?.amount}/${changedPlan?.plan?.interval}`}
              >
                <Sub_Status status="pending" text={t('pending approval by user')} />
              </Stat_> 
            </>
        : null}
      </PageAlwaysVisible>
      <PageAlwaysVisible>
        <Section_Title text={t('Payment History')} />
        <CardsSixty>
          {transactions?.length > 0 &&
            transactions.map((t) => {
              return (
                <Stat_
                  text={`$${prettifyNumbers(t?.amount)}`}
                  title={prettifyDates(t?.createdAt, false)}
                  icon="Payment"
                >
                  <Sub_Status status={t?.status} text={t?.status} />
                </Stat_>
              );
            })}
        </CardsSixty>
      </PageAlwaysVisible>
    </PageToolsForty>
  );
}
