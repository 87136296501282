import {
  Alert_,
  PageAlwaysVisible,
  PageForty,
  PageTitle,
  PageTools,
  Steps_,
  AlertContext,
} from "monica-alexandria";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { StepContext } from "../../../context/StepContext";
import Choose_Plan from "../../partials/addPlan/Choose_Plan";
import Choose_Plan_Per from "../../partials/addPlan/Choose_Plan_Per";
import Toolbar_ from "../../partials/navigation/Toolbar_";
import Add_Plan_Name from "../../partials/addPlan/Add_Plan_Name";
import Add_Plan_Price from "../../partials/addPlan/Add_Plan_Price";
import Choose_Trial from "../../partials/addPlan/Choose_Trial";
import Choose_Multiple from "../../partials/addPlan/Choose_Multiple";
import Choose_Plan_Tax from "../../partials/addPlan/Choose_Plan_Tax";

export default function ADD_PLAN(props) {
  const { t } = useTranslation();
  const [dataInput, setDataInput] = useState({
    plan: "",
    planname: "",
    planper: "",
    planprice: "",
  });
  const [step, setStep] = useState(0);
  const { alerts, setAlerts } = useContext(AlertContext);

  const handleSelection = (value) => {
    setDataInput({ ...dataInput, plan: value });
  };
  // const handlePlanName = (text) => {
  //   setDataInput({ ...dataInput, planname: text });
  // };
  // const handlePer = (value) => {
  //   setDataInput({ ...dataInput, planper: value });
  // };
  // const handlePrice = (text) => {
  //   setDataInput({ ...dataInput, planprice: text });
  // };

  const [priceModel, setPriceModel] = useState({
    name: "",
    interval: "",
    amount: 0,
    taxBehavior: ""
  });
  return (
      <PageForty>
        {alerts ? 
          <Alert_ /> 
        : 
          null
        }
        <PageAlwaysVisible>
          <PageTitle>
            <h1>{t("Add subscription plan")}</h1>
          </PageTitle>
          <StepContext.Provider value={{ step, setStep }}>
            <Steps_
              currentStep={step}
              steps={[
                // <Choose_Plan
                //   selectPlan={handleSelection}
                //   model={priceModel}
                //   setModel={setPriceModel}
                // />,
                <Add_Plan_Price
                  model={priceModel}
                  setModel={setPriceModel}
                  setAlerts={setAlerts}
                />,
                <Choose_Plan_Per
                  interval={priceModel}
                  setInterval={setPriceModel}
                />,
                <Choose_Plan_Tax tax={priceModel} setTax={setPriceModel} />,
                <Choose_Trial model={priceModel} setInterval={setPriceModel} />,
                <Add_Plan_Name name={priceModel} setName={setPriceModel} />,
              ]}
            />
          </StepContext.Provider>
        </PageAlwaysVisible>
      </PageForty>
  );
}
