import { Sidebar_, Sidebar_Box } from "monica-alexandria";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

export default function Toolbar_() {
  const { lang } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Sidebar_>
      <Sidebar_Box
        text={t("Home")}
        icon="Home"
        onClick={() => navigate(`/${lang}/akihiko/griphook`)}
      />
      <Sidebar_Box
        text={t("Translations")}
        icon="Flag"
        onClick={() => navigate(`/${lang}/akihiko/griphook/translations`)}
      />
      <Sidebar_Box
        text={t("Add plan")}
        icon="AddPlan"
        onClick={() => navigate(`/${lang}/akihiko/griphook/add-plan`)}
      />
      <Sidebar_Box
        text={t("Plans")}
        icon="Payment"
        onClick={() => navigate(`/${lang}/akihiko/griphook/plans`)}
      />
      <Sidebar_Box
        text={t("Subs")}
        icon="User"
        onClick={() => navigate(`/${lang}/akihiko/griphook/subscriptions`)}
      />
    </Sidebar_>
  );
}
