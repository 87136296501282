import {
  Builder_,
  Input_,
  Steps_Actions,
  AlertContext,
} from "monica-alexandria";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { StepContext } from "../../../context/StepContext";
import { create_plan } from "../../../services/api/Mocks";
import i18n from "../../../localization/i18next";
import { prettifyPrice } from "../../../helpers/prettifyFunctions";

const AddPlanPrice = styled.div``;

export default function Add_Plan_Price(props) {
  const { setStep } = useContext(StepContext);
  const { t } = useTranslation();
  const lang = i18n.language ? i18n.language : "el";


  const transform_price_to_units = (price) => {
    let newPrice = price.replace(".", ",");
    return parseFloat(newPrice) * 100;
  };

  return (
    <AddPlanPrice>
      <Builder_ title={t("Set plan's price")}>
        <Input_
          placeholder={t("Price")}
          defaultValue={prettifyPrice(props?.model?.amount)}
          onChange={(e) =>
            props.setModel({
              ...props.model,
              amount: transform_price_to_units(e.target.value),
              lang: lang,
            })
          }
        />
      </Builder_>
      <Steps_Actions
        icon_Grey="Back"
        icon_Main="Next"
        onClick_Grey={() => setStep(0)}
        onClick_Main={() => setStep(2)}
        text_Grey={t("Back")}
        text_Main={t("Next")}
      />
    </AddPlanPrice>
  );
}
