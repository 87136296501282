import {
  Builder_,
  CardsSixty,
  Confirmation_,
  Input_,
  On_Click_Card,
  Section_Title,
  ThemeContext,
  logo,
} from "monica-alexandria";
import React, { useContext, useState } from "react";
import currencies from '../../../../data/currencies.json';
import { createModification } from "../../../../services/api/Modifications";
import { useTranslation } from 'react-i18next';


export default function Slider_Plans(props) {
  
  const { theme } = useContext(ThemeContext);
 
  const [selectedPlan, setSelectedPlan] = useState()
  const {t} = useTranslation()

  const declineConfirmation = () => {
    props.setConfirmation(false);
  }; 

  const acceptConfirmation = () => {
    props.setConfirmation(false);
    createModification(props.subscriptionId, props.priceId, props.issuerId)
    .then((res) => {
      props.setSlider(false)
      props.setChangedPlan({
        ...props.changedPlan,
        hasChanged: true,
        plan: selectedPlan
      });
    })
    .catch(err=>{})
  }

  return (
    <div>
      {props.confirmation && (
        <Confirmation_
          message={t("Are you sure you want to change the plan of this poor user?")}
          yes="Yes"
          onYes={() => acceptConfirmation()}
          onNo={() => declineConfirmation()}
          no="No"
        />
      )}
      <Builder_ title={t("Search a plan")}>
        <Input_ placeholder={t("Search by name")} />
      </Builder_>
      <Section_Title text={t("Available plans")} />
      <CardsSixty>
        { props.plans?.map((plan) => {
          return  plan?.priceId !== props.activePriceId && <On_Click_Card
              img={logo.bond[theme]}
              imgBehave="Contain"
              title={`${currencies['EUR']?.symbol}${plan?.amount}/${plan?.interval}`}
              subtitle={plan?.name}
              onClick={() => {
                props.setConfirmation(true)
                setSelectedPlan(plan)
              }}
          /> 
        })}
      </CardsSixty>
    </div>
  );
}
