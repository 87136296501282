import {
    Actions_,
    Builder_,
    CardsForty,
    CardsSixty,
    Main_,
    PageAlwaysVisible,
    PageForty,
    PageTitle,
    Section_Title,
    Single_,
    Stat_,
    Sub_Status,
    ThemeContext,
    Toggle_Stat,
    logo,
    Main_Loader,
    On_Gradient_Card,
    PageSixty,
  
  } from "monica-alexandria";
  import React, {useContext, useEffect, useState } from "react";
  import { prettifyDates } from "../../helpers/prettifiers";
  import store from "../../redux/store";
  import { useLocation } from "react-router-dom";
  import { getTransactions } from "../../services/api/Transactions";
  import { create_portal_session, get_my_subscriptions } from "../../services/api/Mocks";
  import i18n from "../../localization/i18next";
  import { useTranslation } from "react-i18next";
  import { prettifyPrice } from "../../helpers/prettifyFunctions";
  import currencies from '../../data/currencies.json';
  import { Helmet } from "react-helmet";
  import Subscription_Plan from "../../components/subscription/Subscription_Plan";
  import Subscription_History_Block from "../../components/subscription/Subscription_History_Block";

    export default function LOIS_SUBSCRIPTION() {
    const protocol = process.env.REACT_APP_GRIPHOOK_FRONTEND_PROTOCOL;
    const url = process.env.REACT_APP_GRIPHOOK_FRONTEND_HOST;
    const port = process.env.REACT_APP_GRIPHOOK_FRONTEND_PORT;
    const griphookFrontURL = `${protocol}${url}${port}`;

    const user = store.getState().user.user;
    const userId = user.sub;
    const { t } = useTranslation();
    const location = useLocation();
    const { theme } = useContext(ThemeContext);
  
    const params = new URLSearchParams(location?.search);
    const lang = i18n.language ? i18n.language : 'en';
    const [isLoading, setIsLoading] = useState(false);
    // Based on the last transaction
    const [transactions, setTransactions] = useState([])
    const [subscription, setSubscription] = useState({});
    const product_name = params?.get('name');
    const product_category = params?.get('category') || 'lois';
    const redirectURL = `${window.location.origin}${window.location.pathname}?name=${product_name}&category=${product_category}`;
    const paymentURL = `${griphookFrontURL}/${lang}/checkout-details-adsnap`
    const [ initialLoading, setInitialLoading ] = useState(true);
  
    const totalAmount = (tools) => {
      const total = tools?.map(t => t?.amount);  
      if (total) {
        const sum = total.reduce((accumulator, currentValue) => {
          if (typeof currentValue === 'number') {
            return accumulator + currentValue;
          }
          return accumulator;
        }, 0);
        return sum;
      }
    };
  
    const fetchSubscriptions = () => {
      get_my_subscriptions(userId)
      .then(data => {
        if (data?.data?.length > 0) {
          const matchingTools = data?.data.find(subscription => subscription.category === product_category);
          setSubscription(matchingTools || []); // Use an empty object if no matching tools found
        } else {
          setSubscription({}); 
          // No subscriptions found for the user, set as an empty object
        }
      })
      .catch(err => {});
    };
    
    const go_to_edit_subscription = (customer) => {
  
      let payload = {
        customer,
        lang:lang,
        userId,
        redirectURL
      }
  
      create_portal_session(payload)
        .then(data => {
          if (data?.data?.url) {
            setIsLoading(false)
            window.location.assign(data?.data?.url)
          }
          else isLoading(true);
        })
        .catch(err => {})
  }

    function prettifyCurrency(currency) {
      //TODO : change to currency
      if (currency == "eur") return "€";
      else return currency;
    }
  
    useEffect(() => {
        fetchSubscriptions();
    }, [product_category]);
  
    useEffect(()=>{
      if(subscription?.customer){
        getTransactions(subscription?.customer)
          .then((res) => {
            console.log('res', res)
            const matchingTransaction = res?.data?.filter(item2 =>
              subscription?.tools?.some(item1 => item1.subscription === item2.subscription.id)
            );
            let amount = totalAmount(subscription?.tools)
            let daysLeft = Math.ceil((res?.data[0]?.subscription?.current_period_end * 1000 - Date.now()) / (1000 * 60 * 60 * 24));
            let last4 = res?.data[0]?.customer?.invoice_settings?.default_payment_method?.card?.last4 || 'no card'
            setTransactions({
              transactions: matchingTransaction, 
              totalAmount: amount, 
              currency: res.data[0].currency, 
              interval: subscription?.tools[0]?.interval, 
              last4digits: last4, 
              daysLeft:daysLeft,
              nextPayment: res?.data[0]?.subscription?.current_period_end,
              onTrial: res?.data[0]?.subscription?.status==='trialing' ? true : false,
              failedPayment: res?.data[0]?.subscription?.status==='past_due' ? true : false,
              hasCanceled: (subscription?.tools[0]?.cancel_at_period_end || !subscription?.tools[0]?.active) ? true : false
             });
            setInitialLoading(false)
  
          })
          .catch((err) => {});
      }
    }, [subscription])
      console.log('sub', subscription)
      console.log('trans', transactions)
  
      
    return (
      <div>
        <Helmet>
          <title>{`Doitforme | ${product_name} subscription`}</title>
        </Helmet>
          { initialLoading && <Main_Loader/> }
          { !initialLoading && (!product_name) && <Single_
              backgroundImage="https://genius1071.friktoriaservers.net/doitforme/morning/app/boiler/PageNotFound.svg"
              subtitle={t("We're sorry, right now you can't access these information. Try refreshing the page.")}
              text={t("If this error persists or you need assistance, please contact our support team for further assistance.")}
              title={t("Unavailable")}
            /> 
          } 
          { !initialLoading && product_name && <PageSixty>
            <PageAlwaysVisible>
              <PageTitle>
                <h1>{product_name} {t("subscription")}</h1>
              </PageTitle>
              <Subscription_Plan
                currentPlan= {product_name}
                daysLeft={`${transactions?.daysLeft}`}
                nextPayment={transactions?.hasCanceled ? (t("Auto renewal canceled")) : `${prettifyDates(transactions?.nextPayment, true)}`} //auto renewal canceled
                currency= {prettifyCurrency(transactions?.currency)}
                price= {prettifyPrice(transactions?.totalAmount)}
                cardNo= {`${transactions?.last4digits}`}
                onCancel={()=> go_to_edit_subscription(subscription?.customer)}
                onRenew={()=> {!subscription?.tools[0]?.active ? window.location.assign(paymentURL) : go_to_edit_subscription(subscription?.customer)}}
                img={ product_category  ? logo[product_category][theme] : "notFound"}
                onEditCard={()=> { go_to_edit_subscription(subscription?.customer) } }
                hasCanceled={transactions.hasCanceled}
                trial={transactions?.onTrial}
                failed={transactions?.failedPayment}
              />
              {/* <Section_Title text={t("What integrations are included")} />
              <CardsSixty>
                <On_Gradient_Card
                  firstColor="var(--metaOne)" secondColor="var(--metaTwo)"  title={subscription?.tools?.find(sub => sub.property === 'part')?.name} img="https://genius1071.friktoriaservers.net/doitforme/morning/app/adsnap/sources/MetaLogo.png"
                >
                </On_Gradient_Card>
                <On_Gradient_Card className="Google"
                  firstColor="#ff3e30" secondColor="var(--white)" title="Google Ads" img="https://genius1071.friktoriaservers.net/doitforme/morning/app/adsnap/sources/GoogleLogo.png"
                >
                  <h5>{t("Coming soon")}</h5>
                  <h6>{t("You can come back here and activated when available!")}</h6>
                </On_Gradient_Card>
              </CardsSixty> */}

            </PageAlwaysVisible>
            
            <PageAlwaysVisible>
              <Section_Title text={t('Payment History')} />
                {transactions?.transactions?.length > 0 &&
                  transactions?.transactions?.map((t) => {
                    return (
                      <Subscription_History_Block
                          currency={currencies[t?.currency.toUpperCase()]?.symbol}
                          price={prettifyPrice(t?.total)}
                          status={t?.status}
                          date={prettifyDates(t?.created, true)}
                      />
                    );
                  })}
            </PageAlwaysVisible>
          </PageSixty> }
      </div>
    );
  }

  
  