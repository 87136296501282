import { Builder_, Radio_, Steps_Actions } from "monica-alexandria";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { StepContext } from "../../../context/StepContext";

const ChoosePlanPer = styled.div``;

export default function Choose_Plan_Per(props) {
  const { setStep } = useContext(StepContext);
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(props?.interval?.interval || '')
  return (
    <ChoosePlanPer>
      <Builder_ title={t("Choose billing period STEP_3")}>
        <Radio_
          id="Year"
          text={t('Year')}
          name="per"
          value="year"
          checked={selectedValue == "year"}
          onChange={(e) =>{
              setSelectedValue(e.target.value);
              props.setInterval({ ...props.interval, interval: "year" })
            }
          }
        />
        <Radio_
          id="Month"
          text={t('Month')}
          name="per"
          value="month"
          checked={selectedValue == "month"}
          onChange={(e) =>{
              setSelectedValue(e.target.value);
              props.setInterval({ ...props.interval, interval: "month" })
            }
          }
        />
        <Radio_
          id="One off"
          text={t('One off')}
          name="per"
          value="one off"
          checked={selectedValue == 'one off'}
          onChange={(e) =>{
              setSelectedValue(e.target.value);
              props.setInterval({ ...props.interval, interval: 'one off' })
            }
          }
        />
        <Steps_Actions
          icon_Grey="Back"
          icon_Main="Next"
          onClick_Grey={() => setStep(1)}
          onClick_Main={() => setStep(3)}
          text_Grey={t("Back")}
          text_Main={t("Next")}
        />
      </Builder_>
    </ChoosePlanPer>
  );
}
