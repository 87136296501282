import {
  Builder_,
  CardsTools,
  Filters_,
  Google_Search,
  Grey_Link,
  Input_,
  Main_,
  On_Click_Card,
  PageFull,
  PageTitle,
  PageTools,
  Section_Filters_Search,
  Sub_Circles,
  Sub_Color_Coding,
  TBody,
  TD,
  TH,
  THead,
  TR,
  TRH,
  Table,
  TableDesktop,
} from "monica-alexandria";
import React, { useEffect, useState } from "react";
import Toolbar_ from "../../partials/navigation/Toolbar_";
import { useTranslation } from "react-i18next";
import { get_subscribers } from "../../../services/api/Mocks";
import { Subscriber_Filters } from "./partials/Subscriber_Filters";
import { SubscribersHeader } from "./SUBSCRIBERS.css";
import { useNavigate } from "react-router-dom";

export default function SUBSCRIBERS() {
  
    const { t } = useTranslation();

    const navigate = useNavigate();
    
    const [subscribers, setSubscribers] = useState({
        backend_response_subscribers: [],
        elastic_response_subscribers: [],
        currently_showing: "backend",
        subscribers_to_display: [],
        show_loader: true,
    });

    const [filters, setFilters] = useState({
        category: "",
    });

    const [dates, setDates] = useState({
        renderedConversionDates: [],
        renderedLossDates: [],
        conversionDates: {
            sinceDate: "",
            untilDate: "",
        },
        lossDates: {
            sinceDate: "",
            untilDate: "",
        },
    });

    console.log('DATES', dates);
    const [slider, toggleSlider] = useState(false);


    const fetchSubscribers = (payload) => {
        get_subscribers(payload)
        .then((res) => {
            setSubscribers({
                ...subscribers,
                backend_response_subscribers: res?.data?.data,
                show_loader: false,
            });
        }).catch((err) => {
            setSubscribers({
                ...subscribers,
                show_loader: false,
            });
        });
    }

    useEffect(() => {
        const handleDisplayedSubscribers = () => {
            if ( subscribers.elastic_response_subscribers?.length > 0 ) {
                setSubscribers({
                    ...subscribers, 
                    subscribers_to_display: subscribers.elastic_response_subscribers,
                    currently_showing: "elastic"
                });
            } else {
                setSubscribers({
                    ...subscribers, 
                    subscribers_to_display: subscribers.backend_response_subscribers,
                    currently_showing: "backend"
                });
            }
        }

        handleDisplayedSubscribers()
    },[subscribers.backend_response_subscribers?.length, subscribers.elastic_response_subscribers?.length])


    useEffect(() => {
        const fetchWithFilters = () => {
            let payload = {};
            let category = filters.category;
            if ( category ) payload = {...payload, filters: {category} }  

            if ( dates?.conversionDates?.sinceDate && dates?.conversionDates?.untilDate ) payload = {
                ...payload, 
                dates: { 
                    sinceDate: dates.conversionDates.sinceDate, 
                    untilDate: dates.conversionDates.untilDate,
                    type: "conversion",
                }
            }
            else if ( dates?.lossDates?.sinceDate && dates?.lossDates?.untilDate ) payload = {
                ...payload, 
                dates: { 
                    sinceDate: dates.lossDates.sinceDate, 
                    untilDate: dates.lossDates.untilDate,
                    type: "loss",
                }
            }
            if ( payload?.filters || payload?.dates ) fetchSubscribers(payload)
        }
        
        fetchWithFilters()
    },[filters, dates])

    useEffect(() => {
        fetchSubscribers();
    }, []); 


    console.log('SUBSCRBERS', subscribers);

  return (
    <PageFull>
        {slider ? 
            <Subscriber_Filters
                toggleSlider={toggleSlider} 
                filters={filters}
                setFilters={setFilters}
                dates={dates}
                setDates={setDates}
            /> 
        :
            null
        }
      <PageTitle>
        <Grey_Link noPadding iconLeft="Back" onClick={() => navigate(-1)} />
        <h1>{t("Subscribers")}</h1>
      </PageTitle>
      <Filters_ 
        filters={t("Filters")} 
        onFilters = {() => toggleSlider(true)}
        search={
            <Google_Search
                placeholder={t("Search by subscriber")}
                file={subscribers.backend_response_subscribers}
                fileKeys={["username", "firstname", "lastname"]}
                defaultValue={''}
                sanitize={true}
                setResults={(result) => setSubscribers({...subscribers, elastic_response_subscribers: result.data})}
                debounceTime={750}
                minMatchCharacters={1}
                maxMatchCharacters={50}
                disabled={false}
                isCaseSensitive={false}
                maxAcceptedScore={0.6}
            />
        }
      />
      <TableDesktop>
        <Table>
            <THead>
                <TRH>
                    <TH></TH>
                    <TH>#</TH>
                    <TH>{t("Subscriptions")}</TH>
                    <TH>{t("Username")}</TH>
                    <TH>{t("First name")}</TH>
                    <TH>{t("Last name")}</TH>
                </TRH>
            </THead>
            <TBody>
                {subscribers?.subscribers_to_display?.map((subscriber, i)=>
                    <TR>
                        <TD></TD>
                        <TD>{i + 1}</TD>
                        <TD>
                            <Sub_Color_Coding lois dailyprofit bond />
                        </TD>
                        <TD>{subscriber?.username}</TD>
                        <TD>{subscriber?.firstname}</TD>
                        <TD>{subscriber?.lastname}</TD>
                    </TR>
                )}
            </TBody>
        </Table>
      </TableDesktop>
    </PageFull>
  );
}
