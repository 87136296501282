import {
    Actions_,
    Builder_,
    CardsForty,
    Main_,
    PageAlwaysVisible,
    PageTitle,
    Section_Title,
    Single_,
    Stat_,
    Sub_Status,
    ThemeContext,
    Toggle_Stat,
    logo,
    Main_Loader,
    PageSixty,
  
  } from "monica-alexandria";
  import React, {useContext, useEffect, useState } from "react";
  import { prettifyDates } from "../../helpers/prettifiers";
  import store from "../../redux/store";
  import { useLocation } from "react-router-dom";
  import { getTransactions } from "../../services/api/Transactions";
  import { create_portal_session, get_my_subscriptions } from "../../services/api/Mocks";
  import i18n from "../../localization/i18next";
  import { useTranslation } from "react-i18next";

  import { prettifyPrice } from "../../helpers/prettifyFunctions";
  import currencies from '../../data/currencies.json';
  import styled from "styled-components";
import Subscription_Plan from "../../components/subscription/Subscription_Plan";
import Subscription_History_Block from "../../components/subscription/Subscription_History_Block";
import { Helmet } from "react-helmet";
  
  const CheckoutItem = styled.div`
    display: grid;
    grid-template-columns: 1fr minmax(10rem, auto);
    align-items: center;
    grid-gap: 1rem;
    margin-bottom: 1rem;
  `;
  
  const CheckoutItemsRow = styled.div`
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;
  `;
  
  const CheckoutItemSources = styled.div``;
  
  export default function DAILYPROFIT_SUBSCRIPTION() {
    const protocol = process.env.REACT_APP_GRIPHOOK_FRONTEND_PROTOCOL;
    const url = process.env.REACT_APP_GRIPHOOK_FRONTEND_HOST;
    const port = process.env.REACT_APP_GRIPHOOK_FRONTEND_PORT;
  
    const user = store.getState().user.user;
    const userId = user.sub;
    const { t } = useTranslation();
    const location = useLocation();
    const griphookFrontURL = `${protocol}${url}${port}`;
    const { theme } = useContext(ThemeContext);
  
    const params = new URLSearchParams(location?.search);
    const lang = i18n.language ? i18n.language : 'en';
    const [isLoading, setIsLoading] = useState(false);
    // Based on the last transaction
    const [transactions, setTransactions] = useState([])
    const [subscription, setSubscription] = useState({});
    const product_name = params?.get('name');
    const product_category = params?.get('category') || 'dailyprofit';
    const redirectURL = `${window.location.origin}${window.location.pathname}?name=${product_name}&category=${product_category}`;
    const paymentURL = `${griphookFrontURL}/${lang}/checkout-details-questad?category=${product_category}&interval=month`

    const [ initialLoading, setInitialLoading ] = useState(true);
    const [toggles, setToggles] = useState([]);

    const totalAmount = (tools) => {
      const total = tools?.map(t => t?.amount);  
      if (total) {
        const sum = total.reduce((accumulator, currentValue) => {
          if (typeof currentValue === 'number') {
            return accumulator + currentValue;
          }
          return accumulator;
        }, 0);
        return sum;
      }
    };
  
    const fetchSubscriptions = () => {
      get_my_subscriptions(userId)
      .then(data => {
        if (data?.data?.length > 0) {
          const matchingTools = data?.data.find(subscription => subscription.category === product_category);
          setSubscription(matchingTools || []); // Use an empty object if no matching tools found
        } else {
          setSubscription({}); 
          // No subscriptions found for the user, set as an empty object
        }
      })
      .catch(err => {});
    };
    
    const go_to_edit_subscription = (customer) => {
  
      let payload = {
        customer,
        lang:lang,
        userId,
        redirectURL
      }
  
      create_portal_session(payload)
        .then(data => {
          if (data?.data?.url) {
            setIsLoading(false)
            window.location.assign(data?.data?.url)
          }
          else isLoading(true);
        })
        .catch(err => {})
    }
  
    const handleActiveText = (subscription) => {
      let text;
      text = subscription?.status === "cancled" || subscription?.status==="incomplete_expired" ? 'Inactive' : 'Active';
      return text;
    }
  
    const handleToggleClick = (index, source) => {
      const newToggles = [...toggles];
      newToggles[index] = !newToggles[index];
      setToggles(newToggles);
  
      // if (newToggles[index]) {
      //   setCart(prevCart => [...prevCart, source]);
      // } else {
      //   const updatedCart = cart.filter(item => item.priceId !== source.priceId);
      //   setCart(updatedCart);
      // }
    };
  
  
    function prettifyCurrency(currency) {
      //TODO : change to currency
      if (currency == "eur") return "€";
      else return currency;
    }

    // Map over the subscription tools and find the matching card details for each subscription
    const getCardLast4Digits = (tool) => {
      // Find the transaction where the subscription ID matches the tool's subId
      console.log("tool", tool)

      const matchingInvoice = transactions?.transactions?.find(item => tool.subscription === item.subscription.id);
      console.log("matchingInvoice", matchingInvoice)
      // Extract the last 4 digits from the matching transaction, if available
      const last4Digits = matchingInvoice?.customer?.invoice_settings?.default_payment_method?.card?.last4;

      return last4Digits ? last4Digits : "no card"; // Fallback to "no card" if no card info is available
    };


    useEffect(() => {
        fetchSubscriptions();
    }, [product_category]);
  
    useEffect(()=>{
      if(subscription?.customer){
        getTransactions(subscription?.customer)
          .then((res) => {
            console.log('res', res)
            const sortedTransactions = res?.data?.sort((a, b) => b.created - a.created);

            const matchingTransaction = sortedTransactions?.filter(item2 =>
             { return subscription?.tools?.some(item1 => item1.subscription === item2.subscription.id )}
            );
            console.log('matchingTransaction', matchingTransaction)

        // Step 1: Create a map to track active subscriptions by questId
        setTransactions({
          transactions: matchingTransaction, 
          currency: res.data[0].currency, 
          interval: subscription?.tools[0]?.interval, 
          });
        setInitialLoading(false)

      })
      .catch((err) => console.log('err', err));
      }
    }, [subscription])
  
    console.log('sub', subscription)
    console.log('trans', transactions)

    return (
      <div>
        <Helmet>
          <title>{`Doitforme | ${product_name} subscription`}</title>
        </Helmet>
          { initialLoading && <Main_Loader/> }
          { !initialLoading && (!product_name) && <Single_
              backgroundImage="https://genius1071.friktoriaservers.net/doitforme/morning/app/boiler/PageNotFound.svg"
              subtitle={t("We're sorry, right now you can't access these information. Try refreshing the page.")}
              text={t("If this error persists or you need assistance, please contact our support team for further assistance.")}
              title={t("Unavailable")}
            /> 
          } 
          { !initialLoading && product_name && <PageSixty>
            <PageAlwaysVisible>
              <PageTitle>
                <h1>{t("Questad subscriptions")}</h1>
              </PageTitle>
              {subscription ?
              subscription?.tools.filter((tool) => tool.status !== 'canceled')
              .map((tool) =>(<Subscription_Plan
              currentPlan= {product_name}
              daysLeft={`${Math.ceil((tool?.current_period_end * 1000 - Date.now()) / (1000 * 60 * 60 * 24))}`}
              nextPayment={tool?.cancel_at_period_end ? (t("Auto renewal canceled")) : `${prettifyDates(tool?.current_period_end, true)}`} //auto renewal canceled
              currency= {prettifyCurrency(tool?.currency)}
              price= {prettifyPrice(tool?.amount)}
              cardNo={getCardLast4Digits(tool)}
              onCancel={()=> go_to_edit_subscription(tool?.customer)}
              onRenew={()=>go_to_edit_subscription(tool?.customer)}
              img={ product_category  ? logo[product_category][theme] : "notFound"}
              onEditCard={()=> go_to_edit_subscription(tool?.customer)}
              hasCanceled={tool?.cancel_at_period_end}
              trial={tool?.status==='trialing' ? true : false}
              failed={tool?.status==='past_due' ? true : false}
              />))
              : null }
            </PageAlwaysVisible>
            <PageAlwaysVisible>
              <Section_Title text={t('Payment History')} />
                {transactions?.transactions?.length > 0 &&
                  transactions?.transactions?.map((t) => {
                    return (
                      <Subscription_History_Block
                          currency={currencies[t?.currency.toUpperCase()]?.symbol}
                          price={prettifyPrice(t?.total)}
                          status={t?.status}
                          date={prettifyDates(t?.created, true)}
                      />
                    );
                  })}
            </PageAlwaysVisible>
          </PageSixty> }
      </div>
    );
  }
  
  