//         ,''',
//        .' ., .',                                  ../'''',
//        .'. %%, %.',                            .,/' .,%   :
//       .'.% %%%,`%%%'.    .....,,,,,,.....   .,%%% .,%%'. .'
//       : %%% %%%%%%',:%%>>%>' .,>>%>>%>%>>%%>,.   `%%%',% :                         ||```````````````````````````||
//       : %%%%%%%'.,>>>%'   .,%>%>%'.,>%>%' . `%>>>,. `%%%:'                         ||                           ||
//       ` %%%%'.,>>%'  .,%>>%>%' .,%>%>%' .>>%,. `%%>>,. `%                          ||      ΠΡΟΣΟΧΗ: ΝΕΥΡΙΑΖΕΙ   ||
//        `%'.,>>>%'.,%%%%%%%' .,%%>%%>%' >>%%>%>>%.`%% %% `,                         ||     ΑΝ ΑΚΟΥΜΠΗΣΕΤΕ ΤΟΝ    ||
//        ,`%% %%>>>%%%>>%%>%%>>%>>%>%%%  %%>%%>%%>>%>%%%' % %,                       ||     ΚΩΔΙΚΑ Ή ΤΟ ΦΑΙ ΤΟΥ   ||
//      ,%>%'.>>%>%'%>>%%>%%%%>%'                 `%>%>>%%.`%>>%.                     ||                           ||
//    ,%%>' .>%>%'.%>%>>%%%>>%' ,%%>>%%>%>>%>>%>%%,.`%%%>%%. `%>%.                    ===============================
//   ` ,%' .>%%%'.%>%>>%' .,%%%%%%%%'          `%%%%%%.`%%>%% .%%>
//    .%>% .%%>' :%>>%%'.,%%%%%%%%%'.%%%%%' `%%%%.`%%%%%.%%%%> %%>%.
//   ,%>%' >>%%  >%' `%%%%'     `%%%%%%%'.,>,. `%%%%'     `%%%>>%%>%
//  .%%>%' .%%>'  %>>%, %% oO ~ Oo %%%>>'.>>>>>>. `% oO ~ Oo'.%%%'%>%,
//   %>'%> .%>%>%  %%>%%%'  `OoooO'.%%>>'.>>>%>>%>>.`%`OoooO'.%%>% '%>%
//   %',%' %>%>%'  %>%>%>% .%,>,>,   `>'.>>%>%%>>>%>.`%,>,>' %%%%> .>%>,
//   ` %>% `%>>%%. `%%% %' >%%%%%%>,  ' >>%>>%%%>%>>> >>%%' ,%%>%'.%%>>%.
//   .%%'  %%%%>%.   `>%%. %>%%>>>%.>> >>>%>%%%%>%>>.>>>'.>%>%>' %>>%>%%
//   `.%%  `%>>%%>    %%>%  %>>>%%%>>'.>%>>>>%%%>>%>>.>',%>>%'  ,>%'>% '
//    %>'  %%%%%%'    `%%'  %%%%%> >' >>>>%>>%%>>%>>%> %%>%>' .%>%% .%%
//   %>%>, %>%%>>%%,  %>%>% `%%  %>>  >>>%>>>%%>>>>%>>  %%>>,%>%%'.%>%,
//    %>%>%%, `%>%%>%>%, %>%%> ,%>%>>>.>>`.,.  `"   ..'>.%. % %>%>%'.%>%%;
//    %'`%%>%  %%>%%  %>% %'.>%>>%>%%>>%::.  `,   /' ,%>>>%>. >%>%'.%>%'%'
//    ` .%>%'  >%%% %>%%'.>%>%;''.,>>%%>%%::.  ..'.,%>>%>%>,`%  %'.>%%' '
//    %>%>%% `%>  >%%'.%%>%>>%>%>%>>>%>%>>%,,::,%>>%%>%>>%>%% `>>%>'
//     %'`%%>%>>%  %>'.%>>%>%>>;'' ..,,%>%>%%/::%>%%>>%%,,.``% .%>%%
// `    `%>%>>%%' %>%%>>%>>%>%>%>%%>%/'       `%>%%>%>>%%% ' .%'
//       %'  `%>% `%>%%;'' .,>>%>%/',;;;;;,;;;;,`%>%>%,`%'   '
//        `    `  ` `%>%%%>%%>%%;/ @a;;;;;;;;;;;a@  >%>%%'                  //////////////////////////////////////////////
//                   `/////////';, `@a@@a@@a@@aa@',;`//'                    // Περιγραφή: ΡΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑ-
//                       `//////.;;,,............,,;;//'                    // ΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑ
//                         `////;;;;;;;;;;;;;;;;;/'                         // ΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑ
//                            `/////////////////'                           // ΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑ
//                                                                          // ΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΡΡ
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import {
  AlertContext,
  ThemeContext,
  LangContext,
  MenuContext,
  Page,
  RegionContext,
  Theme,
  ThemeDark,
  ThemeLight,
  _Nav,
} from "monica-alexandria";
import React, { useState, useEffect, Profiler } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { GuardedRoute } from "../services/authentication/Guard";
import Navbar_ from "../components/navbar/Navbar_";
import { Protector } from "../services/authentication/Protector";
import TRANSLATIONS from "../admin/views/translations/TRANSLATIONS";
import PANEL from "../admin/views/panel/PANEL";
import PLANS from "../admin/views/plans/PLANS";
import SUBSCRIPTIONS from "../admin/views/subscriptions/SUBSCRIPTIONS";
import SUBSCRIBERS from "../admin/views/subscribers/SUBSCRIBERS";
import MY_PLANS from "../pages/myPlans/MY_PLANS";
import TEST_MQTT from "../pages/testMqtt/TEST_MQTT";
import TEST_PAYMENT from "../pages/testPayment/TEST_PAYMENT";
import ADD_PLAN from "../admin/views/addPlan/ADD_PLAN";
import NO_ACCESS from "../admin/views/noAccess/NO_ACCESS";
import { init, reconstructUrl } from "../helpers/initMenuOptions";
import { useSelector } from "react-redux";
import INVALID from "../pages/invalid/INVALID";
import SUBSCRIPTION from "../admin/views/subscription/SUBSRIPTION";
import i18n from "../localization/i18next";
import { CartContext } from "../context/CartContext";
import PAYMENT from "../pages/payment/PAYMENT";
import LOIS_CHECKOUT from "../pages/lois/LOIS_CHECKOUT.js";
import LOIS_SUBSCRIPTION from "../pages/lois/LOIS_SUBSCRIPTION.js";
import DAILYPROFIT_SUBSCRIPTION from "../pages/dailyprofit/DAILYPROFIT_SUBSCRIPTION.js";
import VANDADOR_SUBSCRIPTION from "../pages/vandador/VANDADOR_SUBSCRIPTION.js";
import VANDADOR_CHECKOUT from "../pages/vandador/VANDADOR_CHECKOUT.js";
import DAILYPROFIT_CHECKOUT from "../pages/dailyprofit/DAILYPROFIT_CHECKOUT.js";
import FIRST from "../pages/dummy/FIRST.js";
import SECOND from "../pages/dummy/SECOND.js";
import LOIS_SUBSCRIBE from "../pages/lois/LOIS_SUBSCRIBE.js";
import LOIS_CHECKOUT_V3 from "../pages/lois/LOIS_CHECKOUT_V3.js";
import LOIS_PLAN from "../pages/lois/LOIS_PLAN.js";
import POCKET_AGENCY_PLAN from "../pages/pocketAgency/POCKET_AGENCY_PLAN.js";
import DAILYPROFIT_PLAN from "../pages/dailyprofit/DAILYPROFIT_PLAN.js";
import PLANS_V3 from "../admin/views/plansV3/PLANS_V3.js";
import CREATE_PLAN_V3 from "../admin/views/createPlan_V3/CREATE_PLAN_V3.js";

export default function Microservice() {
  const authentication = process.env.REACT_APP_AUTHENTICATION;

  const user = useSelector((state) => state.user.user);

  const navigate = useNavigate();

  const [theme, setTheme] = useState();
  const [language, setLanguage] = useState();
  const [region, setRegion] = useState();
  const [activeMenu, setActiveMenu] = useState("Main");
  const [alerts, setAlerts] = useState([]);
  const [cart, setCart] = useState([]);

  const handleRender = (
    id,
    phase,
    actualDuration,
    baseDuration,
    startTime,
    commitTime,
    interactions
  ) => {};

  useEffect(() => {
    if (i18n?.language && reconstructUrl(language))
      navigate(reconstructUrl(language));
  }, [language]);

  useEffect(() => {
    init(user, setTheme, setLanguage, setRegion);
  }, [user]);

  return theme && language && region ? (
    <LangContext.Provider value={{ language, setLanguage }}>
      <RegionContext.Provider value={{ region, setRegion }}>
        <ThemeContext.Provider value={{ theme, setTheme }}>
          <ThemeProvider theme={theme === "dark" ? ThemeDark : ThemeLight}>
            <AlertContext.Provider value={{ alerts, setAlerts }}>
              <CartContext.Provider value={{ cart, setCart }}>
                <Theme>
                  <Page>
                    <MenuContext.Provider value={{ activeMenu, setActiveMenu }}>
                      {authentication == "ON" ? (
                        <GuardedRoute>
                          <Navbar_ />
                        </GuardedRoute>
                      ) : (
                        <Navbar_ />
                      )}
                    </MenuContext.Provider>

                    <Profiler id="all" onRender={handleRender}>
                      <Routes>
                        <Route
                          path="/:lang/test1"
                          element={
                              <FIRST />
                          }
                        />
                        <Route
                          path="/:lang/test2"
                          element={
                              <SECOND />
                          }
                        />
                        <Route
                          path="/:lang/adsnap-subscribe"
                          element={
                              <LOIS_SUBSCRIBE />
                          }
                        />
                        <Route
                          path="/:lang/adsnap-checkout-v3"
                          element={
                              <LOIS_CHECKOUT_V3 />
                          }
                        />
                        <Route
                          path="/"
                          element={
                            <Navigate
                              to={{
                                pathname: `/${language}/my-plans`,
                              }}
                            />
                          }
                        />
                        {/* UNPROTECTED */}
                        <Route
                          path="/:lang/checkout-details-av"
                          element={
                              <VANDADOR_CHECKOUT />
                          }
                        />
                        <Route
                          path="/:lang/payment"
                          element={
                              <PAYMENT />
                          }
                        />
                        {/* PROTECTED */}
                        <Route
                          path="/"
                          element={
                            <Protector>
                              <MY_PLANS />
                            </Protector>
                          }
                        />
                        <Route
                          path="/:lang/"
                          element={
                            <Protector>
                              <MY_PLANS />
                            </Protector>
                          }
                        />
                        <Route
                          path="/:lang/my-plans"
                          element={
                            <Protector>
                              <MY_PLANS />
                            </Protector>
                          }
                        />
                        <Route
                          path="/:lang/subscription"
                          element={
                            <Protector role="Doer">
                              <VANDADOR_SUBSCRIPTION/>
                            </Protector>
                          }
                        />
                        <Route
                          path="/:lang/subscription-questad"
                          element={
                            <Protector role="Doer">
                              <DAILYPROFIT_SUBSCRIPTION/>
                            </Protector>
                          }
                        />
                        <Route
                          path="/:lang/checkout-details-questad"
                          element={
                            <Protector role="Doer">
                              <DAILYPROFIT_CHECKOUT />
                            </Protector>
                          }
                        />
                        <Route
                          path="/:lang/checkout-details-adsnap"
                          element={
                            <Protector role="Doer">
                              <LOIS_SUBSCRIBE />
                            </Protector>
                          }
                        />
                        <Route
                          path="/:lang/subscription-adsnap"
                          element={
                            <Protector role="Doer">
                              <LOIS_SUBSCRIPTION/>
                            </Protector>
                          }
                        />
                        <Route
                          path="/:lang/plan/adsnap"
                          element={
                            <Protector role="Doer">
                              <LOIS_PLAN/>
                              </Protector>
                          }
                        />
                        <Route
                          path="/:lang/plan/pocket-agency"
                          element={
                            <Protector role="Doer">
                              <POCKET_AGENCY_PLAN/>
                            </Protector>
                          }
                        />
                        <Route
                          path="/:lang/plan/questad"
                          element={
                            <Protector role="Doer">
                              <DAILYPROFIT_PLAN/>
                            </Protector>
                          }
                        />
                        {/* ADMIN */}
                        <Route
                          path="/:lang/akihiko/griphook"
                          element={
                            <Protector role="Merchant">
                              <PANEL />
                            </Protector>
                          }
                        />
                        <Route
                          path="/:lang/akihiko/griphook/translations"
                          element={
                            <Protector role="Knight">
                              <TRANSLATIONS />
                            </Protector>
                          }
                        />
                        <Route
                          path="/:lang/akihiko/griphook/plans"
                          element={
                            <Protector role="Merchant">
                              <PLANS />
                            </Protector>
                          }
                        />
                        <Route
                          path="/:lang/akihiko/griphook/plans-v3"
                          element={
                            <Protector role="Merchant">
                              <PLANS_V3/>
                            </Protector>
                          }
                        />
                        <Route
                          path="/:lang/akihiko/griphook/add-plan"
                          element={
                            <Protector role="Baron">
                              <ADD_PLAN />
                            </Protector>
                          }
                        />
                        <Route
                          path="/:lang/akihiko/griphook/create-plan"
                          element={
                            <Protector role="Merchant">
                              <CREATE_PLAN_V3 />
                            </Protector>
                          }
                        />
                        <Route
                          path="/:lang/akihiko/griphook/subscriptions"
                          element={
                            <Protector role="Baron">
                              <SUBSCRIPTIONS />
                            </Protector>
                          }
                        />
                        <Route
                          path="/:lang/akihiko/griphook/subscribers"
                          element={
                            <Protector role="Baron">
                              <SUBSCRIBERS />
                            </Protector>
                          }
                        />
                        <Route
                          path="/:lang/akihiko/griphook/subscription"
                          element={
                            <Protector role="Baron">
                              <SUBSCRIPTION />
                            </Protector>
                          }
                        />
                        <Route
                          path="/:lang/invalid"
                          element={
                            <GuardedRoute>
                              <INVALID />
                            </GuardedRoute>
                          }
                        />
                        {/* TESTING */}
                        <Route
                          path="/:lang/test-mqtt"
                          element={
                            <Protector>
                              <TEST_MQTT />
                            </Protector>
                          }
                        />
                        <Route
                          path="/:lang/test-payment"
                          element={<TEST_PAYMENT />}
                        />
                        {/* MISC */}
                        <Route
                          path="/:lang/access-denied"
                          element={<NO_ACCESS />}
                        />
                      </Routes>
                    </Profiler>
                  </Page>
                </Theme>
              </CartContext.Provider>
            </AlertContext.Provider>
          </ThemeProvider>
        </ThemeContext.Provider>
      </RegionContext.Provider>
    </LangContext.Provider>
  ) : (
    <></>
  );
}
