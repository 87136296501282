import {
    Actions_,
    Builder_,
    Card,
    CardsSixty,
    Main_,
    Main_Plain,
    On_Gradient_Card,
    Radio_,
    Red_,
    Section_Title,
    Stat_,
    ThemeContext,
    Toggle_Stat,
    device,
    logo,
  } from "monica-alexandria";
  import React, { useContext, useEffect, useState } from "react";
  import { useTranslation } from "react-i18next";
  import styled from "styled-components";
  import { prettifyPrice } from "../../../helpers/prettifyFunctions";
  import { CartContext } from "../../../context/CartContext";
  
  const DailytprofitItems = styled.div``;
  
  const DailytprofitItem = styled.div`
    display: grid;
    grid-template-columns: 1fr minmax(10rem, auto);
    align-items: center;
    grid-gap: 1rem;
    margin-bottom: 1rem;
  `;
  
  const DailytprofitItemsRow = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 2rem;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  
    h1,h6{
      white-space: nowrap;
    }
  
    @media ${device.lg} {
      grid-template-columns: 1fr;
    }
  
    @media ${device.md} {
      grid-template-columns: 1fr auto;
    }
  
    @media ${device.sm} {
      grid-template-columns: 1fr;
    }
  `;
  
  const DailytprofitItemsPrice = styled.div`
    h5{white-space: nowrap}
  
    @media ${device.lg} {
      justify-self: flex-end;
    }
  
    @media ${device.md} {
      justify-self: flex-end;
    }
  
    @media ${device.sm} {
      justify-self: flex-end;
    }
  `;
  
  const DailytprofitItemSources = styled.div``;
  
  const DailytprofitItemsTabs = styled.div``;
  
  const DailytprofitRemain = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
  `
  
  const DailytprofitItemsTotal = styled.div`
    display: flex;
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 2px solid var(--greyDark);
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  `
  
  export default function Dailyprofit_Items(props) {
    const { t } = useTranslation();
    const { theme } = useContext(ThemeContext);
    const { cart, setCart } = useContext(CartContext);
    const [remainingFunds, setRemainingFunds] = useState(false);
    const [defaultProfitPrice, setDefaultProfitPrice] = useState(false);

    function prettifyCurrency(currency) {
      //TODO : change to currency
      if (currency == "eur") return "€";
      else return currency;
    }
  

    const caclulateProfit = () =>{
      let budget = props?.defaultItem?.amount/100  // VAT expenses
      let commissionDoit, amountFB;
      console.log('budget', budget)
      
      if (budget < 350) {
        commissionDoit = budget*0.09;
        if (amountFB < 30 && amountFB >= 29) amountFB = 30;
      }
      else if (budget >= 350 && budget < 401) {
          commissionDoit = (9 + (((8-9)/(401 - 350)) * (budget - 350)))*0.01*budget;
      }
      else if (budget >= 401 && budget < 751) {
          commissionDoit = budget*0.08;
      }
      else if (budget >= 751 && budget < 801) {
          commissionDoit = (8 + (((7-8)/(801 - 751)) * (budget - 751)))*0.01*budget;
      }
      else if (budget >= 801 && budget < 1000) {
          commissionDoit = budget*0.07;
      }
      else if (budget >= 1000 && budget < 1551) {
          commissionDoit = (7 + (((4-7)/(1551 - 1000)) * (budget - 1000)))*0.01*budget;
      }
      else if (budget >= 1551) {
          commissionDoit = budget*0.04;
      }
      else {
          commissionDoit = budget*0.04;
      }
      amountFB = budget - commissionDoit;
      setRemainingFunds(amountFB.toFixed(2))
      setDefaultProfitPrice(commissionDoit.toFixed(2))
    }

    useEffect(() =>{
      caclulateProfit()
    },[props?.defaultItem?.amount])
    console.log("remainingFunds", remainingFunds)
    console.log("defaultProfitPrice", defaultProfitPrice)
      return (
      <DailytprofitItems>
        <Builder_>
          <DailytprofitItemsRow>
          {props?.defaultItem ? <Stat_
            title={t("Current plan")}
            text={props?.defaultItem?.name}
            imgBehave="Contain"
            img={ cart[0]?.category ? logo[cart[0]?.category][theme] : "NotFound"}
          /> : null}
            <DailytprofitItemsPrice>
              {/* <h5>{`${prettifyCurrency(props?.defaultItem?.currency)} ${prettifyPrice(props?.defaultItem?.amount, props?.defaultItem?.interval)}/${t("month")}`}</h5> */}
                {/* TODO: make price dynamic */}
              <h5>{prettifyCurrency(props?.defaultItem?.currency)} {defaultProfitPrice}/{t("month")}</h5> 
            </DailytprofitItemsPrice>
          </DailytprofitItemsRow>
        </Builder_>
        {/* TODO: make this dynamic */}
        <DailytprofitRemain>
            <h5>{t("Remaining funds")}:</h5>
            <h4>{remainingFunds}/{t("month")}</h4>
        </DailytprofitRemain>
        <DailytprofitItemsTotal>
            <h3>{t("Total")}:</h3>
            {/* TODO: make this dynamic */}
            <h3>{prettifyCurrency(props?.defaultItem?.currency)} {prettifyPrice(props?.defaultItem?.amount)}/{t("month")}</h3>
        </DailytprofitItemsTotal>
      </DailytprofitItems>
    );
  }
  