import { Builder_, Radio_, Steps_Actions } from "monica-alexandria";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { StepContext } from "../../../context/StepContext";

const ChoosePlanTax = styled.div``;

export default function Choose_Plan_Tax(props) {
  const { setStep } = useContext(StepContext);
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(props?.tax?.taxBehavior || '')

  return (
  <ChoosePlanTax>
    <Builder_ title={t("Choose if tax is inclusive or exclusive")}>
      <Radio_
        id="Exclusive"
        text={t('Exclusive')}
        name="is"
        value="exclusive"
        checked={selectedValue == 'exclusive'}
        onChange={(e) =>{
            setSelectedValue(e.target.value)
            props.setTax({ ...props.tax, taxBehavior: "exclusive" })
          }
        }
      />
      <Radio_
        id="Inclusive"
        text={t('Inclusive')}
        name="is"
        value="inclusive"
        checked={selectedValue == 'inclusive'}
        onChange={(e) =>{
            setSelectedValue(e.target.value)
            props.setTax({ ...props.tax, taxBehavior: "inclusive" })
          }
        }
      />
      <Steps_Actions
        icon_Grey="Back"
        icon_Main="Next"
        onClick_Grey={() => setStep(2)}
        onClick_Main={() => setStep(4)}
        text_Grey={t("Back")}
        text_Main={t("Next")}
      />
    </Builder_>
  </ChoosePlanTax>

  );
}