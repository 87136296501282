import { Main_, Main_Plain, device } from 'monica-alexandria';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

// Styles for the Dropdown button to fit entirely within the form
export const DropdownButton = styled.div`
  position: relative; // Set relative for positioning internal dropdown
  display: flex;
  align-items: center;
  justify-content: flex-start; // Adjust to start to align with the left side of the form
  width: 100%; // Ensure it takes the full width to align correctly under the input
  margin-top: 0.5rem; // Provide some space between the input and this dropdown

  @media ${device.md} {
    justify-content: flex-start;
  }
`;

// Styles for the dropdown actions to appear under the dropdown button
export const DropdownButtonActions = styled.div`
  background: ${(p) => p.theme.low};
  position: absolute;
  top: 100%; // Position directly below the dropdown button
  left: 0; // Align with the left side of the dropdown button
  width: 100%; // Match width with the dropdown button
  border-radius: var(--smallRadius);
  padding: var(--smallPads);
  z-index: 5;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); // Shadow for better visibility

  .last {
    padding-top: 0.5rem;
    border-top: 1px solid ${(p) => p.theme.mid};
  }
`;

export const Dropdown_Button = (props) => {
    const [showOptions, toggleShowOptions] = useState(false);
    const dropdownPopupRef = useRef(null);

    // Function to close the OptionsPopup when clicking outside
    const closeOptionsPopup = (event) => {
        if (dropdownPopupRef.current && !dropdownPopupRef.current.contains(event.target)) {
            toggleShowOptions(false);
        }
    };

    useEffect(() => {
        // Add event listener when component mounts
        document.addEventListener('mousedown', closeOptionsPopup);
        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', closeOptionsPopup);
        };
    }, []);

    return (
        <DropdownButton ref={dropdownPopupRef}>
            <Main_ text={props.textMain} iconRight='Dropdown' onClick={() => toggleShowOptions(!showOptions)} />
            {showOptions && 
                <DropdownButtonActions>
                    <ul>
                        {props.text1 ? <li><Main_Plain text={props.text1} size='medium' iconLeft={props.icon1} onClick={props.onClick1} /></li> : null}
                        {props.text2 ? <li><Main_Plain text={props.text2} size='medium' iconLeft={props.icon2} onClick={props.onClick2}/></li> : null}
                        {props.textLast ? <li className='last'><Main_Plain text={props.textLast} size='medium' iconLeft={props.iconLast} onClick={props.onClickLast} /></li> : null}
                    </ul>
                </DropdownButtonActions>
            }
        </DropdownButton>
    )
}
