import axios from 'axios';
const protocol = process.env.REACT_APP_ERISED_BACKEND_PROTOCOL;
const url = process.env.REACT_APP_ERISED_BACKEND_HOST;
const port = process.env.REACT_APP_ERISED_BACKEND_PORT;
const erisedURL = `${protocol}${url}${port}`


const g_protocol = process.env.REACT_APP_GRIPHOOK_BACKEND_PROTOCOL;
const g_url = process.env.REACT_APP_GRIPHOOK_BACKEND_HOST;
const g_port = process.env.REACT_APP_GRIPHOOK_BACKEND_PORT;
const griphookURL = `${g_protocol}${g_url}${g_port}`

// admin level
const get_subscribers = (options) => {
    return new Promise((resolve, reject) => {

        let params = `${options?.filters ? "filters="+encodeURIComponent(JSON.stringify(options.filters))+"&" : ""}`+
            `${options?.elastic ? "elastic="+options.elastic+"&" : ""}`+
            `${options?.sorting ? "sorting="+encodeURIComponent(JSON.stringify(options.sorting))+"&" : ""}`+
            `${options?.dates ? "dates="+encodeURIComponent(JSON.stringify(options.dates))+"&" : ""}`+
            `${options?.pagination ? "pagination="+options.pagination+"&" : ""}`

           
       
        axios.get(`${erisedURL}/subscriptions/getAllSubscribers?${params}`, {
            headers:{"x-erised-secret": "HH{T~Cw=p5ng6:gW[~v+YhtL~9y_+ec4^'NGR{(>YtR%8f$/j93rerwBsB9"}
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}

const get_subscriptions = (options) => {


    return new Promise((resolve, reject) => {
       
        let params = `${options?.filters ? "filters="+encodeURIComponent(JSON.stringify(options.filters))+"&" : ""}`+
                        `${options?.elastic ? "elastic="+options.elastic+"&" : ""}`+
                        `${options?.sorting ? "sorting="+encodeURIComponent(JSON.stringify(options.sorting))+"&" : ""}`+
                        `${options?.pagination ? "pagination="+options.pagination+"&" : ""}`

      
        axios.get(`${erisedURL}/subscriptions/getSubscriptions?${params}`)
            .then(res => {
                resolve(res)
            })
            .catch(() => {
                resolve(false);
            })
    })
}


const get_plans = () => {

    return new Promise((resolve, reject) => {
        axios.get(`${griphookURL}/plans/getProducts`, {
            headers:{"x-griphook-secret": "HH{T~Cw=p5ng6:gW[~v+YhtL~9y_+ec4^'NGR{(>YtR%8f$/j93rerwBsB9"}
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}

const plan_exists = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`${griphookURL}/plans/planExists`, {
            headers:{"x-griphook-secret": "HH{T~Cw=p5ng6:gW[~v+YhtL~9y_+ec4^'NGR{(>YtR%8f$/j93rerwBsB9"},
            plan: data
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}

const get_my_subscriptions = (userId) => {

    return new Promise((resolve, reject) => {
        axios.get(`${erisedURL}/subscriptions/getMySubscriptions?userId=${userId}`)
        .then(res => resolve(res))
        .catch(err=> resolve(false))
    })
}

const create_plan = (priceModel) =>{
    return new Promise((resolve, reject) => {
        axios.post(`${griphookURL}/plans/createProduct`,{
            data: priceModel
        })
        .then(res=> {
            resolve(res)
        })
        .catch(err=>{
            resolve(err)
        })
    })
}

const delete_plan = (planId) =>{
    return new Promise((resolve, reject) => {
        axios.post(`${griphookURL}/plans/deleteProduct`,{
            headers:{"x-griphook-secret": "HH{T~Cw=p5ng6:gW[~v+YhtL~9y_+ec4^'NGR{(>YtR%8f$/j93rerwBsB9"},
            plan: planId
        })
        .then(res=> {
            resolve(res)
        })
        .catch(err=>{
            resolve(err)
        })
    })
}

// user level

const plan_subscribers = (product_priceId) => {
    return new Promise((resolve, reject) => {
        axios.post(`${erisedURL}/subscriptions/getSubscription?=${product_priceId}`, {
            headers:{"x-erised-secret": "HH{T~Cw=p5ng6:gW[~v+YhtL~9y_+ec4^'NGR{(>YtR%8f$/j93rerwBsB9"}
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}

const send_metadata_fields = (metadata) => {
    return new Promise((resolve, reject) => {
        axios.post(`${griphookURL}/checkout/checkout`, {
            metadata
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}
const create_portal_session = (payload) => {
    return new Promise((resolve, reject) => {
        axios.post(`${griphookURL}/checkout/editSubscription`, {
            payload
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}

const send_payment_link = (email, lang, paymentLink, planPrice) => {
    return new Promise((resolve, reject) => {
        axios.post(`${griphookURL}/checkout/sendPaymentLink`, {
            email,
            lang,
            paymentLink,
            planPrice
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}

const send_metadata_fields_dummy = (metadata) => {
    return new Promise((resolve, reject) => {
        axios.post(`${griphookURL}/checkout/cartCheckout`, {
            metadata
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}

export {
    get_subscribers,
    get_subscriptions,
    get_plans,
    delete_plan,
    create_plan,
    plan_subscribers,
    send_metadata_fields,
    create_portal_session,
    send_payment_link,
    get_my_subscriptions,
    plan_exists,
    send_metadata_fields_dummy
};