import {
  Builder_,
  CardsSixty,
  CardsTools,
  Dropdown_,
  Filters_,
  Form,
  Full_Card_Loader,
  Google_Search,
  Grey_Link,
  Input_,
  Main_,
  Main_Loader,
  Main_Plain,
  On_Click_Card,
  PageFull,
  PageTitle,
  PageTools,
  Partial_Loader,
  Section_Filters_Search,
  Slider,
  Slider_,
  Sub_Color_Coding,
  TBody,
  TD,
  TH,
  THead,
  TR,
  TRH,
  Table,
  TableCard,
  TableCardActions,
  TableDesktop,
  TableMobile,
} from "monica-alexandria";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  create_portal_session,
  get_my_subscriptions,
  get_subscriptions,
} from "../../../services/api/Mocks";
import Toolbar_ from "../../partials/navigation/Toolbar_";
import { prettifyPrice } from "../../../helpers/prettifyFunctions";
import { MyPlansActions, MyPlansFooter, MyPlansStatus } from "../../../pages/myPlans/MY_PLANS.css";
import store from "../../../redux/store";
import i18n from "../../../localization/i18next";
import Subscriptions_Filters from "./partials/Subscriptions_Filters";
import { useNavigate } from "react-router-dom";

export default function SUBSCRIPTIONS() {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const userId = store.getState()?.user?.user?.sub;
  const lang = i18n.language ? i18n.language : "en";

  const [subscriptions, setSubscriptions] = useState({data:[],loading:true});

  const [userPlan, setUserPlan] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  const [inputActive, setInputActive] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [displayedSubs, setDisplayedSubs] = useState([]);

  const [slider, toggleSlider] = useState(false);
  const [activeSlider, setActiveSlider] = useState("6955000909");
  const [filters, toggleFilters] = useState(false)

  //filters
  const [selectedOption, setSelectedOption] = useState({data:'', key_field:'', key:'', name:''})
  const [selectedStatus, setSelectedStatus] = useState({data:'', key_field:'', key:'', name:''})
  const [sortHandle , setSortHandle] = useState({key:'', order:'',value:''})



  const go_to_edit_subscription = (customer) => {
    let payload = {
      customer,
      lang: lang,
      userId,
    };
    create_portal_session(payload)
      .then((data) => {
        if (data?.data?.url) window.location.assign(data?.data?.url);
      })
      .catch((err) => {});
  };
  

  function getPlanName(planCategory) {
    const planMapping = {
      lois: 'AdSnap',
      dayilyprofit: 'Questad',
      monica: 'Mini Websites',
      bond: 'Tailormade',
    };
  
    return planMapping[planCategory] || '';
  }
  
  const applySearchResults = () => {
    if (searchResults?.data?.length) setDisplayedSubs(searchResults?.data);
    else setDisplayedSubs(subscriptions?.data);
  };

  useEffect(() => {
      if ( searchResults?.data?.length === 0 ) {
        setDisplayedSubs(subscriptions?.data);
      }
          
  }, [subscriptions?.data]);

  useEffect(() => {
      applySearchResults();
  }, [searchResults?.input]);

  //get subscriptions from db
  useEffect(() => {

    const handleFilters = () => {
      let options = {filters: {}, 
        sorting: {}
      }

      if ( sortHandle.key ) {
        options.sorting[sortHandle.key] = sortHandle.order
      }
    
      if ( selectedOption.key_field ) {
        options.filters[selectedOption.key_field] = selectedOption.key
      }

      if ( selectedStatus.key_field ) {
        options.filters[selectedStatus.key_field] = selectedStatus.key
      }

      if (Object.keys(options.filters).length === 0 && options.filters.constructor === Object) {
        delete options.filters;
      } 
      if (Object.keys(options.sorting).length === 0 && options.sorting.constructor === Object) {
        delete options.sorting;
      } 
      get_subscriptions(options)
        .then((res) => {
          setSubscriptions({
            ...subscriptions,
            data: res.data?.data,
            loading: false,
          }) 
        })
        .catch((err) => {
          setSubscriptions({
            ...subscriptions,
            loading: false,
          }) 
        });
    }

    handleFilters()
  }, [sortHandle,selectedOption, selectedStatus]);

  return (
    <PageFull className={slider ? "HideScroll" : ""}>  
      {slider && activeSlider == currentUser.id ? (
        <Slider_
          title={`${currentUser.firstname} ${currentUser.lastname}`}
          onClose={() => toggleSlider(false)}
          onClick={() => toggleSlider(false)}
        >
          <Builder_ title={t("All subscriptions")}>
            <CardsSixty>
              {userPlan?.map((sub, i) => (
                <On_Click_Card
                  img="https://genius1071.friktoriaservers.net/doitforme/morning/app/icons/Essential.png"
                  title={sub.name}
                  subtitle={`€${prettifyPrice(sub.amount)}${
                    sub?.interval !== "" ? "/" + sub.interval : ""
                  }`}
                >
                  <MyPlansFooter>
                    <MyPlansStatus variant="Active">
                      <picture></picture>
                      {sub?.active == true ? (
                        <h6>{t("Active")}</h6>
                      ) : (
                        <h6>{t("Inactive")}</h6>
                      )}
                    </MyPlansStatus>
                    <Main_Plain
                      iconLeft="Edit"
                      text={t("Click to edit")}
                      noPadding
                      onClick={() => go_to_edit_subscription(sub?.customer)}
                    />
                  </MyPlansFooter>
                  <MyPlansActions></MyPlansActions>
                </On_Click_Card>
              ))}
            </CardsSixty>
          </Builder_>
        </Slider_>
      ) : null}
      {filters && 
      <Subscriptions_Filters 
        toggleFilters={toggleFilters}  
        {...{ selectedOption, setSelectedOption }}  
        {...{ selectedStatus, setSelectedStatus }}  
        {...{sortHandle, setSortHandle}}
      />}
      <PageTitle>
        <Grey_Link noPadding iconLeft="Back" onClick={() => navigate(-1)} />
        <h1>{t("Subscriptions")}</h1>
      </PageTitle>
      <Filters_
        filters={t("filters")}
        onFilters = {()=>toggleFilters(!filters)}
        search={
          <Google_Search
            placeholder={t("Search by plan, username, plan category")}
            file={subscriptions.data}
            fileKeys={["plan_name", "username", "category"]}
            sanitize={false}
            setResults={(result) => setSearchResults(result)} 
            getInput={(active) => setInputActive(active)}
          />
        }
      />
      <TableDesktop>
        <Table>
          <THead>
            <TRH>
              <TH></TH>
              <TH>#</TH>
              <TH>{t("Subscriptions")}</TH>
              <TH>
                {t("Username")}
              </TH>
            </TRH>
          </THead>
          {!subscriptions.loading ? 
            <TBody>
              {displayedSubs.map((sub, i) =>(
                <TR>
                  <TD>
                    <Main_Plain noPadding iconLeft="Edit" 
                      // TODO: onClick
                    />
                  </TD>
                  <TD>{i + 1}</TD>
                  <TD>
                    <Sub_Color_Coding 
                      lois={sub.category === 'lois'}
                      dayilyprofit={sub.category === 'dailyprofit'}
                      bond = {sub.category === 'bond'}
                    />
                  </TD>
                  <TD>{sub.username}</TD>
                </TR>
              ))}
            </TBody>
          :  
            <Partial_Loader /> 
          } 
        </Table>
      </TableDesktop>
      <TableMobile>
          <TableCard>
            <table>
              <TableCardActions></TableCardActions>
              <TBody>
                <TR>
                  <TD></TD>
                </TR>
              </TBody>
            </table>
          </TableCard>
      </TableMobile> 
    </PageFull>
  );
}
