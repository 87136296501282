const convert_translations = (translations) => {

    var languageEN = {
        translations: {

        }
    }
    var languageEL = {
        translations: {

        }
    }
    var languageDE = {
        translations: {

        }
    }
    var languageIT = {
        translations: {

        }
    }

    if ( translations?.length > 0 ) translations.forEach((t) => {
        if (t?.lang) {
            switch (t.lang){
                case ('el'):{
                    if (t?.key && t?.value) languageEL.translations[t?.key] = t?.value;
                    break;
                }
                case ('de'): {
                    if (t?.key && t?.value) languageDE.translations[t?.key] = t?.value;
                    break;
                }
                case ('en'): {
                    if (t?.key && t?.value) languageEN.translations[t?.key] = t?.value;
                    break;
                }
                case ('it'): {
                    if (t?.key && t?.value) languageIT.translations[t?.key] = t?.value;
                    break;
                }
            }
        }
    })

    
    return {languageDE, languageEL, languageEN, languageIT}
}

export default convert_translations;