export const formatDate = (date) => {
    const localeDateFormat = new Intl.DateTimeFormat().formatToParts(new Date());

    const dateFormat = localeDateFormat.map((part) => {
        if (part.type === 'day') {
            return 'dd';
        } else if (part.type === 'month') {
            return 'mm';
        } else if (part.type === 'year') {
            return 'yyyy';
        }
        return part.value;
    }).join('/');


    let format = dateFormat.split('//');          

    let dateParts = date.toLocaleDateString().split('/');
    let formattedDate = [];

    let indexes = {month: -1, day: -1, year: -1};
    let counter = 0;
    for ( let part of format ) {
        if ( part.includes('dd') ) indexes.day = counter;
        if ( part.includes('mm') ) indexes.month = counter;
        if ( part.includes('yyyy') ) indexes.year = counter;
        counter++;
    }

    formattedDate.push(dateParts[indexes.year]);
    formattedDate.push(dateParts[indexes.month].length === 1 ? "0"+dateParts[indexes.month] : dateParts[indexes.month] );
    formattedDate.push(dateParts[indexes.day].length === 1 ? "0"+dateParts[indexes.day] : dateParts[indexes.day] );

    return formattedDate?.length > 0 ? formattedDate.join('-') : "";
};