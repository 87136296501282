import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AlertContext, Alert_, Builder_, Input_, Radio_ } from "monica-alexandria";
import Payment_Free_Trial from "./Payment_Free_Trial";
import { findAllByTestId } from "@testing-library/react";
import store from "../../../redux/store";
import { coupon_validation } from "../../../services/api/Subscriptions";
const PaymentModifications = styled.div``;

export default function Payment_Modifications(props) {
  const { t } = useTranslation();
  const { alerts, setAlerts } = useContext(AlertContext);
  const [couponInput, setCouponInput] = useState('')
  const [valid, setIsValid] = useState(false)
  const [loading, setIsLoading] = useState(true)
  const [defaultCoupon, setDefaultCoupon]  = useState('NOCARD15');
  const [couponInfo, setCouponInfo] = useState(false);
  const params = store.getState().params;

  const alertMessage = (message, status) => {
    setAlerts([
        ...alerts,
        {
            message: {
                text: message,
                status: status,
            },
        },
    ]);
  };

  const checkCouponIsValidOnClick =(promoCode) =>{
    coupon_validation(promoCode, params?.category, props?.customer)
    .then(res => {
      if(res?.data?.code === 'S4000' || res?.data?.code === 'S4003' || res?.data?.code === 'S4002' || res?.data?.code === 'S4001') {
        setDefaultCoupon('')
        setCouponInput('')
        setIsValid(false)
        props?.setTrial(false)
        props?.setDiscount('')
        alertMessage('Not a valid coupon', 'Error' )

      }
      else if(res?.data?.code === 'S4001'){
        setDefaultCoupon('')
        setCouponInput('')
        setIsValid(false)
        props?.setTrial(false)
        props?.setDiscount('')
        alertMessage('You have already redeemed this promo code', 'Error' )
      }
      else{
        props?.setDiscount(res?.data)
        setIsValid(true)
        props?.setTrial(true)
        setCouponInfo(res?.data?.couponInfo)
        alertMessage('Coupon is valid', 'Success' )
      }
    })
  }

  const checkForValidOnPageLoad =(promoCode) =>{
    coupon_validation(promoCode, params?.category, props?.customer)
    .then(res => {
      if(res?.data?.code === 'S4000' || res?.data?.code === 'S4003' || res?.data?.code === 'S4002' || res?.data?.code === 'S4001') {
        setDefaultCoupon('')
        setCouponInput('')
        setIsValid(false)
        props?.setTrial(false)
        props?.setDiscount('')
        setIsLoading(false);
      }
      else{
        setCouponInput('NOCARD15')
        props?.setDiscount(res?.data)
        setIsValid(true)
        props?.setTrial(true)
        setCouponInfo(res?.data?.couponInfo)
        alertMessage('Coupon is valid', 'Success' )
        setIsLoading(false);
      }
    })
  }
  
  const handleCouponInput = (e) =>{
    setDefaultCoupon('')
    props?.setDiscount('')
    setIsValid(false)
    props?.setTrial(false)
    setCouponInput(e.target.value)
  }
  const removeCoupon =  () =>{
    setDefaultCoupon('')
    setIsValid(false)
    props?.setTrial(false)
    props?.setDiscount('')
    setCouponInput('')
  }

  useEffect(()=>{
    if(!couponInput && params?.category==='lois'){
      setCouponInput('')
      setDefaultCoupon('')
      checkForValidOnPageLoad('NOCARD15')
    }
    else {
      setCouponInput('')
      setIsLoading(false)
    }
  },[])

  useEffect(()=>{
    if(!props?.trial){
      setDefaultCoupon('')
      setIsValid(false)
      props?.setTrial(false)
      setCouponInput('')
    }
  },[])

  return (
    <PaymentModifications>
    <Alert_ messages={alerts} /> 
      {/* <Builder_ title={t("Select a payment method")}>
        <Radio_ text={t("Stripe")} checked />
      </Builder_> */}
      <Builder_ title={t("If you have a coupon code add it here")}>
       {!loading && <Input_
          placeholder={t("Coupon code")}
          list={couponInput && valid ? [couponInput] : []} //Max one code per checkout
          defaultListItem = {couponInput!==defaultCoupon ? defaultCoupon : ''}
          defaultValue = {defaultCoupon ? defaultCoupon : ''}
          value= {couponInput}
          onDelete={()=> removeCoupon()}
          onChange ={(e) => handleCouponInput(e)}
          icon_ListItem="Coupon"
          add={t("Submit")}
          onAdd={() => checkCouponIsValidOnClick(couponInput)}
        />}
      </Builder_>
      {valid && <Payment_Free_Trial couponInfo={couponInfo} coupon={couponInput && valid ? couponInput : defaultCoupon}/> }
    </PaymentModifications>
  );
}
