import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Actions_,
  AlertContext,
  Alert_,
  Builder_,
  Checkbox_,
  Focus_,
  Form,
  Grey_,
  Grey_Link,
  Input_,
  Main_Loader,
  PageAlwaysVisible,
  PageDesktopPadding,
  PageSixty,
  PageTitle,
  Section_Title,
} from "monica-alexandria";
import { getProductswithCategoryAsInput } from "../../services/api/Plans";
import Checkout_Items from "../checkoutDetails/components/Checkout_Items";
import { CartContext } from "../../context/CartContext";
import styled, { ThemeContext } from "styled-components";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import i18n from "../../localization/i18next";
import store from "../../redux/store.js";
import { useNavigate } from "react-router-dom";
import { handleCheckoutSession, validateCart, validateFields } from "../checkoutDetails/utils";
import q from 'query-string'
import { Helmet } from "react-helmet";
import Checkout_Address from "../../components/checkoutDetails/Checkout_Address.js";
import Dailyprofit_Items from "./components/Dailyprofit_Items.js";
import { getAmountFromQuest } from "../../services/api/Quests";

const CheckoutExtraInput = styled.div`
  margin-top: 1.5rem;

  input{
    background: var(--light);
    border: 1px solid var(--greyLight);
    border-radius: 16px;
  }

  .Invoice{
    margin-bottom: .5rem;

    input{
      background: var(--light) !important;
    }
  }
`

export default function DAILYPROFIT_CHECKOUT() {
  const { t } = useTranslation();
  const { cart, setCart } = useContext(CartContext);
  const { theme } = useContext(ThemeContext);
  const { alerts, setAlerts } = useContext(AlertContext);
  const navigate = useNavigate();

  const [billingFields, setBillingFields] = useState({})
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [defaultItem, setDefaultItem] = useState(false);
  const [stripePromise, setStripePromise] = useState(() => loadStripe(process.env.REACT_APP_STRIPE_PK_TEST));
  const [selectedOption, setSelectedOption] = useState('month'); // 'year' or 'month'
  const [selectedAmount, setSelectedAmount] = useState(false);
  const [showVatInput, setShowVatInput] = useState(true);

  const lang = i18n.language ? i18n.language : "el";
  const user = store.getState().user.user;
  const queryParams = q.parse(window.location.search);

  const handleStripeTheme = () => {
    let stripeTheme = theme == "dark" ? "night" : "stripe";
    return stripeTheme;
  };

  const [messages, setMessages] = useState({
    errors: [],
    helpers: [],
    successes: [],
  });

  const options = {
    locale: lang,
    appearance: {
      theme: handleStripeTheme(),
      labels: "floating",
      variables: {
        fontSizeBase: "1.6",
        spacingUnit: "4",
        borderRadius: "1rem",
      },
    },
  };

  const alertMessage = (message, status) => {
    setAlerts([
        ...alerts,
        {
            message: {
                text: message,
                status: status,
            },
        },
    ]);
  };

  const validateVatErrors = () => {
    if (!billingFields?.vat?.length) {
      setMessages({ helpers:[], errors: ['Vat is empty'] });
      setBillingFields({...billingFields, vat: ''})
      return false;
    }
    else if (messages.helpers?.length) {
      setMessages({successes:[], helpers:[], errors: messages?.helpers });
      setBillingFields({...billingFields, vat: ''})
      return false;
    }else {
      return true;
    }
  }


  const createCustomer = () =>{
    console.log('billingFields', billingFields)

    let valid = validateFields(alertMessage, billingFields, t)
      let validVat =  showVatInput ? validateVatErrors() : true;

      let checkedSources = validateCart(alertMessage, cart, queryParams?.priceId) 
      console.log('valid', valid)
      console.log('checkedSources', checkedSources)
      
      if(valid && checkedSources & validVat) handleCheckoutSession(navigate, billingFields, user, cart[0]?.category);
  }


  // Function to initialize cart and set default item
  const initializeCartAndDefaultItem = (res, query) => {
    console.log('res',res)
    sessionStorage.removeItem('cart');
    setCart([])
    setDefaultItem(res[0]);

    getAmountFromQuest(query?.quest)
    .then(res => {
      let amount = res?.quest?.questionnaires[res?.quest?.questionnaires?.length - 1];
      setSelectedAmount(parseInt(amount?.answer*100))
      setIsLoading(false);
    })
    .catch(err=> console.log(err))

  };

  useEffect(() => {
    console.log('queryParams',queryParams )
      // Fetch products only once and call the function to initialize cart and set default item
    let query = JSON.stringify(queryParams) !== '{}' ? queryParams : store.getState().params
    store.dispatch({type:'SAVE_CART_PARAMS', payload: query}); 

    getProductswithCategoryAsInput(query, selectedOption)
    .then(res => initializeCartAndDefaultItem(res, query))
    .catch((err) => console.log(err));

  }, [selectedOption]);

  // Update sessionStorage whenever the cart changes
  useEffect(() => {
    // Only set the default item to cart if it's not already in the cart
    if (defaultItem && selectedAmount && !cart.some((item) => item.priceId === defaultItem.priceId)) {
      console.log('def', defaultItem)
      defaultItem.amount = selectedAmount
      delete defaultItem.priceId;
      setCart((prevCart) => [...prevCart, defaultItem]); // Use callback form to ensure up-to-date cart
    }        
    store.dispatch({type:'SAVE_CART', payload: cart});

  }, [selectedAmount, defaultItem, cart]);
  console.log('isLoading',isLoading)


  if(isLoading) return <Main_Loader/>
  return (
    <PageSixty>
      <Helmet>
        <title> Doitforme | {defaultItem?.name} cart</title>
      </Helmet>
      <PageAlwaysVisible>
      <Alert_ messages={alerts} /> 
        <PageTitle>
          <Grey_Link iconLeft="Back" noPadding onClick={()=> navigate(-1)} />
          <h1>{t("Cart")}</h1>
        </PageTitle>
        <Dailyprofit_Items defaultItem={defaultItem} queryParams={queryParams} sources={products} selectedOption={selectedOption} setSelectedOption={setSelectedOption} /> 
      </PageAlwaysVisible>
      <PageAlwaysVisible>
        <PageDesktopPadding>
          <Form>
            <h3>{t("Fill in your billing information")}</h3>
            <Elements stripe={stripePromise} options={options}>
              <Checkout_Address 
                messages={messages} 
                setMessages={setMessages} 
                queryParams={queryParams} 
                billingFields={billingFields} 
                setBillingFields={setBillingFields}
                showVatInput={showVatInput}
                setShowVatInput={setShowVatInput}
              />
            </Elements>
            <Actions_ end>
              <Focus_ text={t("Proceed to checkout")} iconRight="Next" onClick={()=> createCustomer()}/>
            </Actions_>
          </Form>
        </PageDesktopPadding>
      </PageAlwaysVisible>
    </PageSixty>
  );
}
