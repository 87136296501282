import React, { useEffect } from 'react'
import styled from 'styled-components'
import NoCardIllu from '../../assets/billing/NoCard.svg'
import { Actions_, AlertContext, Alert_, Builder_, Focus_, Form, Input_Phone_Register, Main_Loader, Main_Text_Loader, PhoneContext, countries } from 'monica-alexandria'
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { CartContext } from "../../context/CartContext";
import { create_confirm_subscription_no_card } from '../../services/api/Subscriptions'
import store from "../../redux/store";
import i18n from "../../localization/i18next";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { prettifyPrice } from '../../helpers/prettifyFunctions';
import { validatePhone } from '../../pages/checkoutDetails/utils';

const clients = require('../../config/index').config

const GA4_QUESTAD = process.env.REACT_APP_GOOGLE_ANALYTICS_QUESTAD;
const GA4_ADSNAP = process.env.REACT_APP_GOOGLE_ANALYTICS_ADSNAP;

const NoCard = styled.div``

export default function No_Card(props) {
  const cart  = useContext(CartContext);
  const { t } = useTranslation(); 
  const navigate = useNavigate();
  const { alerts, setAlerts } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] =  useState(false)
  const user = store.getState().user.user;
  const [customerData, setCustomerData] = useState({email:user?.username, name: `${user?.firstname} ${user?.lastname}` })
  const currentCart = cart.length>1 ? cart : store.getState().cart
  const lang = i18n.language ? i18n.language : "el";
  const quest = store.getState().params.quest;
  const on_session_customer = props?.customer || false;

  const [phoneCode, setPhoneCode] = useState();
  const [currentPhone, setCurrentPhone] = useState('');
  const [messages, setMessages] = useState({
    errorsPhone: [],
    helpersPhone: [],
    successesPhone: [],
    errorsEmail: [],
    helpersEmail: [],
    successesEmail: [],
});

  const chooseRedirectURI = (category, property) => {
    switch (true) {
      case category === 'lois':
        return `${clients['lois']}/${lang}/success-payment`;
      case category === 'bond' && property === "vandador":
        return `${clients['vandador']}/${lang}/more-info?email=${email}&plan=${currentCart[0]?.name}`;
      case category === 'bond' && property === "agency":
        return `${clients['agency']}/${lang}/more-info?email=${email}&plan=${currentCart[0]?.name}`;
      case category === 'dailyprofit':
        return `${clients['dailyprofit']}/${lang}/success-payment`;
      default:
        return `${clients['griphook']}/${lang}/my-plans`;
    }
  }




  const gtag_purchase_event = () =>{
    // Send a custom event
    ReactGA.event("purchase", { 
        value: prettifyPrice(currentCart[0]?.amount),
        category: currentCart[0]?.category,
        action: "purchase",
        coupon: props?.discount,
        trial: props?.trail,
        currency: 'EUR'
      });
    }
    
  const alertMessage = (message, status) => {
    
    setAlerts([
        ...alerts,
        {
            message: {
                text: message,
                status: status,
            },
        },
    ]);
    setTimeout(() => {
      setAlerts((prevAlerts) => prevAlerts.slice(1));
    }, 5000);
  };

  const createAndConfirmSubscritpion = () =>{
    if(!customerData.phone || customerData.phone==='' || customerData.phone===phoneCode) alertMessage(t("Please fill in your phone number"), 'Error');
    else{
      create_confirm_subscription_no_card(on_session_customer, customerData, currentCart, props?.trial, user?.sub, user?.email?.email, false, quest, props?.discount)
      .then(res=>{
        if(res?.code==='SCC4001' || res?.code==='SCC4000') {
          setLoading(false);
          alertMessage(t("Something went wrong!"), 'Error');
        }
        else {
          setLoading(true);
          handleServerResponse(res?.data)
        }
      })
    }
  }

  const handleServerResponse = async (response) => {
    console.log('RESPONSE:', response)
    if (response?.length>0 && response.some(paymentIntent => 
        paymentIntent?.last_payment_error && 
        JSON.stringify(paymentIntent?.last_payment_error) !== "{}"
        )) {
        response.forEach(paymentIntent => {
            alertMessage(`${paymentIntent?.last_payment_error?.message} `, 'Error');
        });
        setLoading(false);
    }
    else {
        gtag_purchase_event();
        window.sessionStorage.removeItem('billing');
        window.sessionStorage.removeItem('customer');
        window.location.replace(chooseRedirectURI(currentCart[0]?.category, currentCart[0]?.property))
        window.localStorage.removeItem('cart');
    }
}
  console.log("coupon", props?.discount)
  console.log("prop customer", props?.customer)
  console.log("customer Data", customerData)


  const validatePhoneFormat = (e) => {
    let dataPhone = validatePhone(e.target.value, t);
    let countriesList = countries.find(item => item?.phoneCode===phoneCode)
    setMessages({
        ...messages,
        helpersPhone: dataPhone.helper,
        successesPhone: dataPhone.success,
    });
    setCurrentPhone(e.target.value)

    setCustomerData({...customerData, phone: `${phoneCode}${e.target.value}`,address:{country: countriesList?.code}});
  };

  useEffect(() => {
    if (process.env.REACT_APP_SERVER === 'PRODUCTION') {
      if (currentCart[0]?.category === 'dailyprofit') {
        ReactGA.initialize(GA4_QUESTAD);
      } else {
        ReactGA.initialize(GA4_ADSNAP);
      }
    }
  }, [currentCart]);

  if (loading) return <Main_Text_Loader text={t('We are setting up your free trial. Please do not leave this page.')}/>
  return (
    <NoCard>
      <Alert_/>
        <Form>
            <img src={NoCardIllu} />
            <PhoneContext.Provider value={{ phoneCode, setPhoneCode }}>
            <Builder_ title={t('Fill in your phone number')} noPads>
              <Input_Phone_Register
                  placeholder={t('Phone number')}
                  listIcon="Phone"
                  errors={messages.errorsPhone}
                  helpers={messages.helpersPhone}
                  successes={messages.successesPhone}
                  onChange={validatePhoneFormat}
                  value={currentPhone}
                />
              </Builder_>
              </PhoneContext.Provider>
            <Actions_ end>
                <Focus_ text={t("Claim your") + " " + (props?.daysOff ? `${props?.daysOff}-day ` : "") + t("trial")} iconRight="Coupon" onClick={() => createAndConfirmSubscritpion()}/>
            </Actions_>
        </Form>
    </NoCard>
  )
}
