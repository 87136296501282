import { AlertContext, Builder_, Input_, Steps_Actions } from "monica-alexandria";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { StepContext } from "../../../context/StepContext";
import { create_plan } from "../../../services/api/Mocks";

const AddPlanName = styled.div``;

export default function Add_Plan_Name(props) {
  const { setStep } = useContext(StepContext);
  const { t } = useTranslation();
  const { alerts, setAlerts } = useContext(AlertContext);
  let successMessage = (t("The plan has been succesfully created!"));

  const createPlan = (model) => {
    create_plan(model)
      .then((res) => {
        setAlerts([
          ...alerts,
          {
            message: {
              text: successMessage,
              status: "Success",
            },
          },
        ]);
        props.setName({ name: "", interval: "", amount: 0, category: "" , taxBehavior: ""});
        setStep(0);
      })
      .catch((err) => {
        setStep(0);
      });
  };
  return (
    <AddPlanName>
      <Builder_ title={t("Name your plan")}>
        <Input_
          placeholder={t("Plan name")}
          defaultValue={props?.name?.name}
          onChange={(e) =>
            props.setName({ ...props.name, name: e.target.value })
          }
        />
      </Builder_>
      <Steps_Actions
        icon_Grey="Back"
        icon_Main="Next"
        onClick_Grey={() => setStep(4)}
        onClick_Main={() => createPlan(props.name)}
        text_Grey={t("Back")}
        text_Main={t("Create")}
      />
    </AddPlanName>
  );
}
