import { Form } from "monica-alexandria";
import styled from "styled-components";

export const PlansStatus = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 0 2rem 1rem 2rem;
`;

export const PlansSlider = styled.div``;

export const Clipboard = styled.div`
  position: relative;
  padding: var(--smallPads);
  padding-right: 10rem;
  padding-bottom: 1.5rem;
  background: ${(p) => p.theme.high};
  border-radius: var(--smallRadius);

  p {
    font-family: monospace;
    color: var(--greyLight);
  }

  button {
    position: absolute;
    right: -10px;
    top: -15px;
  }
`;
