import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { AlertContext ,Builder_, Dropdown_, Input_, Radio_,} from "monica-alexandria";
import { validateEmail } from "../../../../helpers/validators";

const CustomPeriod = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: end;
    justify-content: center;
    gap:0.5rem;
`

const NumerInput = styled.input`
  width: 100%;
  border: none;
  background: ${(props) => props.theme.background};
  color: ${(props) => props.theme.color};
  box-shadow: ${(props) => props.theme.in};
  border-radius: var(--smallRadius);
  transition-property: box-shadow;
  transition-duration: 0.5s;
  padding: var(--normalPads);
  font-size: var(--normalText);

  &::placeholder {
    color: inherit;
    opacity: 0.5;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
  }

  &:focus {
    outline: 0;
    box-shadow: ${(props) => props.theme.inFocus};
  }
`

const AdvancedOptions = styled.div`
    /* box-shadow: var(--insetShadow); */
    padding-top: 0.5rem;
    border-radius: var(--smallRadius);
`

export const Advanced_Options = (props) =>{

    const {t} = useTranslation();
    const {alerts, setAlerts} = useContext(AlertContext);
    const {lang} = useParams();
    const navigate = useNavigate();
   




    console.log('props.recurring.interval', props.recurring);
    


    return(
        <AdvancedOptions>
           
                <Dropdown_>
                    <select
                        value={props.recurring?.interval_count === 30 ? '30' : props.recurring?.interval}
                        onChange={(e) => {e.target.value === '30' ? props?.setRecurring({interval_count:30, interval: 'day'} ) : props?.setRecurring({interval_count: 1, interval:e.target.value})}}
                    >   
                        <option value='30'>{t("30 days")}</option>
                        <option value='month'>{t("Monthly")}</option>
                        <option value='year'>{t("Yearly")}</option>
                        <option value='day'>{t("Daily")}</option>
                        <option value='week'>{t("Weekly")}</option>
                       
                    </select>
                </Dropdown_>    


        </AdvancedOptions>   
    )
}





