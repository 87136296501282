
export const vatCountryCodes = {
  "AT": "AT",
  "BE": "BE",
  "CY": "CY",
  "EE": "EE",
  "FI": "FI",
  "FR": "FRAB",
  "DE": "DE",
  "GR": "EL",
  "IE": "IE",
  "IT": "IT",
  "LV": "LV",
  "LT": "LT",
  "LU": "LU",
  "MT": "MT",
  "NL": "NL",
  "PT": "PT",
  "SK": "SK",
  "SI": "SI",
  "ES": "ESA"
}
