
// Displays price in a more marketable form
export const prettifyPrice = (price, interval) => {
    if (!price) return "0,00";
    if(interval && interval==="year") price = price/12
    const parsedPrice = parseFloat((price.toString().replace(',', '.'))/100).toFixed(2);
    const [whole, decimals] = parsedPrice.split('.');
    const paddedDecimals = decimals.padEnd(2, '0');

    return `${whole.replace(/\B(?=(\d{3})+(?!\d))/g, ".")},${paddedDecimals}`;

}

export const create_plan_message = (taxBehavior, interval, interval_count, t) => {        
    console.log("taxBehavior", taxBehavior)
    console.log("interval", interval)

    console.log("interval_count", interval_count)

    let result = "";

    if(taxBehavior === "exclusive" || taxBehavior==="unspecified") result += "+ " + t("TAX") + " ";

    if(interval == "day") result = (interval_count == 1 || !interval_count) ? result + "/ " + t("day") : result + "/ " + interval_count + " " + t("days")
    else if(interval == "week") result = (interval_count == 1 || !interval_count) ? result + "/ " + t("week") : result + "/ " + interval_count + " " + t("weeks")
    else if(interval == "month") result = (interval_count == 1 || !interval_count) ? result + "/ " + t("month") : result + "/ " + interval_count + " " + t("months")
    else if(interval == "year") result = (interval_count == 1 || !interval_count) ? result + "/ " + t("year") : result + "/ " + interval_count + " " + t("years")

    return result;
}