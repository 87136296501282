import { Builder_, Radio_, Steps_Actions } from "monica-alexandria";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { StepContext } from "../../../context/StepContext";
import { useNavigate } from "react-router-dom";

const ChoosePlan = styled.div``;

export default function Choose_Plan({ selectPlan, setModel, model }) {
  const { setStep } = useContext(StepContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState(model.category || "");

  const handleSelection = (e) => {
    setSelectedValue(e.target.value)
    setModel({ ...model, category: e.target.value });
    selectPlan(e.target.value);
  };

  return (
    <ChoosePlan>
      <Builder_
        title={t("Pick the tool you wish to create new subscription STEP_1")}
      >
        <Radio_
          id="bond"
          text={t('Tailormade')}
          name="plan"
          value="bond"
          checked={selectedValue === "bond"}
          onChange={(e) => handleSelection(e)}
        />
        {/* <Radio_
          id="lois"
          text={t('Adsnap')}
          name="plan"
          value="lois"
          checked={selectedValue === "lois"}
          onChange={(e) => handleSelection(e)}
        />
        <Radio_
          id="dailyprofit"
          text={t('Questad')}
          name="plan"
          value="dailyprofit"
          checked={selectedValue === "dailyprofit"}
          onChange={(e) => handleSelection(e)}
        />
        <Radio_
          id="monica"
          text={t('Mini websites')}
          name="plan"
          value="monica"
          checked={selectedValue === "monica"}
          onChange={(e) => handleSelection(e)}
        /> */}
      </Builder_>
      <Steps_Actions
        icon_Grey="Xicon"
        icon_Main="Next"
        onClick_Grey={() => navigate(-1)}
        onClick_Main={() => setStep(1)}
        text_Grey={t("Exit")}
        text_Main={t("Next")}
      />
    </ChoosePlan>
  );
}
