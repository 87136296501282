import { loadStripe } from "@stripe/stripe-js";
import {
  Actions_,
  AlertContext,
  Alert_,
  Focus_,
  Form,
  Grey_,
  Main_Loader,
  PageAlwaysVisible,
  PageDesktopOnly,
  PageDesktopPadding,
  PageForty,
  PageSixty,
  PageTitle,
  Single_,
  ThemeContext,
} from "monica-alexandria";
import React, { useContext, useEffect, useState } from "react";
import Payment_Details from "./components/Payment_Details";
import { Elements, LinkAuthenticationElement } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import Payment_Modifications from "./components/Payment_modifications";
import Payment_Summary from "./components/Payment_Summary";
import { CartContext } from "../../context/CartContext";
import { calculate_tax, create_payment_intent } from "../../services/api/Subscriptions";
import store from "../../redux/store";
import i18n from "../../localization/i18next";
import { Helmet } from "react-helmet";
import Payment_No_Card from "./components/Payment_No_Card";

export default function PAYMENT() {
  const { alerts, setAlerts } = useContext(AlertContext);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_TEST);
  const { cart } = useContext(CartContext);
  const { t } = useTranslation();
  const lang = i18n.language ? i18n.language : "el";
  const { theme } = useContext(ThemeContext);
  const [invoice, setInvoice] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [trial, setTrial] = useState(true)
  const customer = window.sessionStorage.getItem("customer");
  const user = store.getState().user.user;
  const currentCart = cart?.length>0 ? cart : store.getState().cart
  const params = store.getState().params
  const [coupon, setCoupon] = useState('');

  const handleStripeTheme = () => {
    let stripeTheme = theme == "dark" ? "night" : "stripe";
    return stripeTheme;
  };

  const calculate_invoice_amount = () =>{
    calculate_tax(customer, currentCart, coupon.couponId)
    .then(res=> {
      setInvoice(res)
      setIsLoading(false)
    })
    .catch(err=> console.log(err))
  }

  useEffect(()=>{
    if(currentCart.length > 0) {
      if(params?.category !== 'lois') setTrial(false)
      calculate_invoice_amount()
    }
  },[cart, coupon])

  const options = {
    mode: "subscription",
    amount: invoice?.total || 0,
    currency: invoice?.currency || '€',
    // automatic_payment_methods: {enabled: true},
    paymentMethodCreation: 'manual',
    locale: lang || 'el',
    appearance: {
      theme: handleStripeTheme(),
      labels: "floating",
      variables: {
        fontSizeBase: "1.6",
        spacingUnit: "4",
        borderRadius: "1rem",
      },
    },
  };

  if(isLoading){ return(
       <Single_
        backgroundImage="https://genius1071.friktoriaservers.net/doitforme/morning/app/griphook/ToStripe.svg"
        title={t("Checkout")}
        subtitle={t("Redirecting you to Stripe")}
        text={t("Hold on tight, while we teleport you to the magical land of Stripe where payments are as smooth as butter and the unicorns roam free!")}
      />
    );
  }
  else return (
    <PageSixty>
      <Helmet>
        <title>Doitforme | Billing information</title>
      </Helmet>
      <Alert_ messages={alerts} duration={5000}/>
      <PageAlwaysVisible>
        <PageTitle>
          <h1>{t("Payment")}</h1>
        </PageTitle>
        <Payment_Modifications customer={customer} validation={invoice?.custom_code} discount={coupon} setDiscount={setCoupon} trial={trial} setTrial={setTrial}/>
        <Payment_Summary trialDays={coupon?.couponInfo?.trialDays} invoice={invoice}  trial={trial}/>
      </PageAlwaysVisible>
      <PageAlwaysVisible>
        <PageDesktopPadding>
        {options && (coupon?.couponInfo?.noCard==='false' || !coupon?.couponInfo?.noCard) ?  
          <Form>
            <Elements stripe={stripePromise} options={options}>
              <h3>{t("Billing information")}</h3>
              <Payment_Details discount={coupon?.couponId} trial={trial} total={invoice?.total} currency={invoice?.currency}/>
            </Elements> 
          </Form> :
            <Elements stripe={stripePromise} options={options}>
              <Payment_No_Card discount={coupon?.couponId} trial={trial} total={invoice?.total} currency={invoice?.currency}/>
            </Elements> 
        }
        </PageDesktopPadding>
      </PageAlwaysVisible>
    </PageSixty>
  );
}
