import { Form } from 'monica-alexandria';
import styled from 'styled-components';

export const SubscribersHeader = styled(Form)`
    margin-bottom: 4rem;
    position: sticky;
    top: 2rem;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto;
    grid-gap: 2rem;
`;

export const SubscribersCardFooter = styled.div`
    margin-top: 2rem;
`;

export const SubscribersCardFooterSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
`;

export const SubscribersCardFooterSubs = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;

    div {
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 50%;
    }

    .lois {
        background-image: radial-gradient(
            circle,
            #1e88e5 50%,
            var(--white) 100%
        );
    }
`;
