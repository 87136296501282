import React, { useEffect, useState } from 'react'
import {
    Builder_,
    Checkbox_,
    Dates_,
    Dropdown_,
    Main_,
    Slider_,
    countries,
} from 'monica-alexandria';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const SubscriptionsFilters = styled.div``

export default function Subscriptions_Filters(props) {
    const [statusOption, setStatusOption] = useState(props.selectedStatus);
    const [option, setOption] = useState(props.selectedOption)
    const {t} = useTranslation()
    const sortOptions = {
        UseridDesc: { key: 'userId', order: -1 ,text: 'Userid (Desc)' ,value: 'UseridDesc'},
        UseridAsc: { key: 'userId', order: 1, text: 'Userid (Asc)' , value: 'UseridAsc'},
        NameAsc: { key: 'username', order: 1 ,text: 'Username (Asc)', value: 'NameAsc'},
        NameDesc: { key: 'username', order: -1 ,text: 'Username (Desc)', value: 'NameDesc' },
      };

 
    const handleRadioChange = (id, key_field, key) => {
        if (id === 'All')  props.setSelectedOption({...props.selectedOption, name: id , key_field: '', key: '',})
       else props.setSelectedOption({...option,name:id, key_field: key_field, key: key})
    };
  
    const handleStatusChange = (id, key_field, key) => {
        props.setSelectedStatus({...statusOption, name: id, key_field: key_field, key:key})
    };

    const handleSortChange = (sortOption) =>{
        if (sortOptions.hasOwnProperty(sortOption)) {
            const selectedSortOption = sortOptions[sortOption];
            props.setSortHandle({ ...props.sortHandle, ...selectedSortOption });
        }
    }
    //     const applyFilters = () => {
    //         if (sortOptions.hasOwnProperty(sortOption)) {
    //         const selectedSortOption = sortOptions[sortOption];
    //         props.setSortHandle({ ...props.sortHandle, ...selectedSortOption });
    //         }
    //          props.toggleFilters(false)
    //    };

    return (
        <SubscriptionsFilters>   
            <Slider_ 
                title={t("Filters")} 
                text={t("Apply")}
                onClose = {() => props.toggleFilters(false)}
                onClick = {() => props.toggleFilters(false)}
            >
            <Builder_ title={t("Sort by")}>
            <Dropdown_>
                <select defaultValue={props.sortHandle.value}  onChange={(e) => handleSortChange(e.target.value)}>
                {Object.entries(sortOptions).map(([key, option]) => (
                    <option key={key} value={key}>
                    {option.text}
                    </option>
                ))}
                </select>
            </Dropdown_>
            </Builder_>
               <Builder_ title={t("Status")}>
                    <Checkbox_
                        text={t("All subscriptions")}
                        id="AllSubscriptions"
                        name = 'status'
                        checked={props.selectedStatus.name ==='AllSubscriptions'}
                        onChange={() => handleStatusChange('AllSubscriptions','','')}
                    />
                    <Checkbox_ 
                        text={t("Active subscriptions")}
                        id="ActiveSubscriptions"
                        name = 'status'
                        checked={props.selectedStatus.name=== 'ActiveSubscriptions'}
                        onChange={() => handleStatusChange('ActiveSubscriptions','status', 'active' )}
                    />
                    <Checkbox_ 
                        text={t("Inactive subscriptions")}
                        id="InactiveSubscriptions"
                        name = 'status'
                        checked={props.selectedStatus.name=== 'InactiveSubscriptions'}
                        onChange={() => handleStatusChange('InactiveSubscriptions','status', 'inactive' )}
                    />
                    <Checkbox_ 
                        text={t("On Trial")}
                        id="Trial"
                        name = 'status'
                        checked={props.selectedStatus.name=== 'Trial'}
                        onChange={()=>handleStatusChange('Trial', 'status', 'trialing')}
                    />
                    <div></div>
                </Builder_>
                <Builder_ title={t("Show")}>
                <Checkbox_
                        text={t("All")}
                        id="All"
                        name = 'option'
                        checked={props.selectedOption.name ==='All'}
                        onChange={() => handleRadioChange('All')}
                    />
                    <Checkbox_
                        text="Adsnap"
                        id="AdSnap"
                        name = 'option'
                        checked={props.selectedOption.name ==='AdSnap'}
                        onChange={() => handleRadioChange('AdSnap','category','lois')}
                    />
                    <Checkbox_ 
                        text="Questad"
                        id="Questad"
                        name = 'option'
                        checked={props.selectedOption.name=== 'Questad'}
                        onChange={() => handleRadioChange('Questad','category', 'dailyprofit' )}
                    />
                    <Checkbox_ 
                        text="Mini Website"
                        id="MiniWebsite"
                        name = 'option'
                        checked={props.selectedOption.name=== 'MiniWebsite'}
                        onChange={()=>handleRadioChange('MiniWebsite', 'category', 'monica')}
                    />
                    <Checkbox_ 
                        text="Tailormade"
                        id="Tailormade"
                        name = 'option'
                        checked={props.selectedOption.name=== 'Tailormade'}
                        onChange={()=>handleRadioChange('Tailormade', 'category', 'bond')}
                    />
                    <div></div>
                </Builder_>
            </Slider_>
        </SubscriptionsFilters>
  )
}
