import React from 'react'
import { Checkout } from '../../styles/Layout.css'
import { Builder_, PageAlwaysVisible, PageTitle } from 'monica-alexandria'
import { useTranslation } from 'react-i18next'
import Summary_ from '../../components/checkout/Summary_'
import styled from 'styled-components'
import Lois_Checkout from './Lois_Checkout'

const CheckourGrid = styled.div`
    display: grid;
    grid-template-columns: 30rem 1fr;
    grid-gap: 4rem;
    align-items: flex-start;
`

export default function SECOND() {

    const {t} = useTranslation()

  return (
    <Checkout>
        <PageAlwaysVisible>
            <PageTitle>
                <h1>{t("Checkout")}</h1>
            </PageTitle>
            <CheckourGrid>
                <Lois_Checkout />
                <Summary_ />
            </CheckourGrid>
        </PageAlwaysVisible>
    </Checkout>
  )
}
