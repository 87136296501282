import styled from "styled-components";

export const MyPlansFooter = styled.div`
    margin-top: 1rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 1rem;
`

export const MyPlansStatus = styled.div`
    display: flex;
    align-items: flex-start;
    gap: .5rem;

    picture{
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background: ${p => 
            p.variant === 'Active' ? 'var(--success)' :
            p.variant === 'Innactive' ? 'var(--error)' :
            'var(--warning)'
        };
    }

    h6{
        color: ${p => 
            p.variant === 'Active' ? 'var(--success)' :
            p.variant === 'Innactive' ? 'var(--error)' :
            'var(--warning)'
        };
    }
`

export const MyPlansActions = styled.div`
    display: flex;
    align-items: center;
    /* justify-content: center; */
    margin-top: 1rem;
`