import { Builder_, CardsTools, Grey_Link, Input_, On_Click_Card, PageFull, PageTitle, PageTools, Slider_ } from 'monica-alexandria'
import React, { useEffect, useState } from 'react'
import { findMissing, getTranslations, updateTranslations } from '../../../services/api/Translations';
import Toolbar_ from '../../partials/navigation/Toolbar_';

import _ from 'lodash';
import { Keymaker_ } from '../../partials/keymaker/Keymaker_';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function TRANSLATIONS() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  
  return (
    <PageFull>
      <PageTitle>               
        <Grey_Link iconLeft="Back" noPadding onClick = {()=> navigate(-1)} />
        <h1>{t('Available languages')}</h1>
      </PageTitle>
      <Keymaker_ />
    </PageFull>
  )
}
