import { Builder_, Input_ } from 'monica-alexandria';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const ClientsEmails= styled.div`
    //padding-top: 2rem;
`

export const Clients_Emails = (props) => {

    const [currentEmail, setCurrentEmail] = useState('');
    const initialMessages = {helpers: [], successes:[], errors:[]}
    const [messages, setMessages] = useState(initialMessages)
    const {t} = useTranslation();


    const validateEmailFormat = (email) => {
 
        let helpers = [];
        let successes = [];
    
        // Regular expression to match a valid email format
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,50}$/;
    
        if (email !== '') {
            if (!emailRegex.test(email)) {
                helpers.push(t("Correct email format email@domain.com"));
            } else {
                successes.push(t("Email address is valid."));
            }
        }
        
        setMessages(prevMessages => ({
                ...prevMessages,
                helpers: helpers,
                successes: successes,
        
        }));
        setCurrentEmail(email);
    };

    const addEmail = () =>{
        const emailExists = props?.emailList.some(email => email === currentEmail); // Checks if email already exists in the list
        let emailErrors = messages?.helpers;
        console.log('emailErrors',emailErrors);
        
        if (emailErrors?.length > 0) {
             setMessages(prevMessages => ({
                    ...prevMessages,
                    helpers: [],
                    successes: [],
                    errors: emailErrors
            }));
        } else if (emailExists) {
            setMessages(prevMessages => ({
                ...prevMessages,
                helpers: [],
                successes: [],
                errors: [t('Email exists')]
            }));
        } else {
            props?.setEmailList(prevList => [...prevList, currentEmail]); // Correctly update the email list
            setMessages(initialMessages)
        }
        setCurrentEmail(''); // Reset input field
    }
    

    const deleteEmail = (emailIndex) => {
        props?.setEmailList(prevList => prevList.filter((_, index) => index !== emailIndex));
    };

  return (
    <ClientsEmails>
        <Builder_ title={t("Add an email address")}>
            <Input_
                placeholder={t('Email address')}
                list = {props?.emailList || []}
                icon_ListItem="Email"
                onChange={(e)=> validateEmailFormat(e.target.value)}
                value={currentEmail}
                onAdd={addEmail}
                onDelete={(i) => deleteEmail(i)}
                add={t('Add')}
                helpers={messages?.helpers}
                successes={messages?.successes}
                errors={messages?.errors}
                required
            />
        </Builder_>  
    </ClientsEmails>
    
  )
}
