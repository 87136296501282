import React, { useState } from 'react'
import styled from 'styled-components'

const Tabs = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 0.5rem;
    background-color: ${(p)=>p.theme.low};
    box-sizing: border-box;
    box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);
    padding: 0.25rem;
    width: 50rem;
    //min-width: 50rem;
    font-size: 14px;
`;

const Tab = styled.label`
  text-align: center;
  width: 100%;
`;

const TabInput = styled.input`
  display: none;

  &:checked + h6 {
    background-color: ${(p)=>p.theme.mid} ;
    color: var(--focus);
  }
`;

const TabText = styled.h6`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: none;
  padding: var(--smallPads);
  transition: all 0.15s ease-in-out;

    &:hover {
        background-color: ${(p)=>p.theme.mid} ;
    }

`;

export const Tabs_ = (props) => {

  return (
    <Tabs>
        <Tab>
            {Tabs.map}
            <TabInput  onClick={()=> props.handleType('recurring')} type="radio" name="radio" checked={props.selectedType==='recurring'} />
            <TabText>Recurring</TabText>
        </Tab>
        <Tab >
            <TabInput onClick={()=>props.handleType('oneoff')} type="radio" name="radio" checked={props.selectedType==='oneoff'}/>
            <TabText>One-off</TabText>
        </Tab>
    </Tabs>
  )
}
