import { Builder_, IconSvg, Input_, AlertContext, Alert_, device, Main_Loader } from 'monica-alexandria';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import React, { useContext, useEffect, useState } from "react";
import store from "../../redux/store"
import { coupon_validation } from "../../services/api/Subscriptions";
const Coupon = styled.div``

const CouponGraph = styled.div`
  background: linear-gradient(to bottom right, var(--focus), var(--main));
  border-radius: 2rem;
  position: relative;
  min-height: 11rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  h1,
  p,
  h4 {
    color: var(--white) !important;
  }

  @media ${device.lg} {
    flex-direction: column;
    align-items: normal;
    min-height: none;
  }

  @media ${device.md} {
    align-items: center;
    min-height: 11rem;
    flex-direction: row;
  }

  @media ${device.sm} {
    flex-direction: column;
    align-items: normal;
    min-height: none;
  }
`;

const CouponSissors = styled.div`
  padding: 2rem 1rem 2rem 3.5rem;
  border-right: 2px dashed var(--white);

  @media ${device.lg} {
    border-right: none;
    border-bottom: 2px dashed var(--white);
    padding: 2rem 3.5rem;
  }

  @media ${device.md} {
    border-bottom: none;
    padding: 2rem 1rem 2rem 3.5rem;
    border-right: 2px dashed var(--white);
  }

  @media ${device.sm} {
    border-right: none;
    border-bottom: 2px dashed var(--white);
    padding: 2rem 3.5rem;
  }
`;

const CouponPerks = styled.div`
  padding: 2rem 3.5rem 2rem 1rem;

  ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    li {
      display: flex;
      align-items: flex-start;
      gap: 1rem;

      svg {
        width: var(--normalIcon);
        fill: var(--white);
      }
    }
  }

  @media ${device.lg} {
    padding: 2rem 3.5rem;
  }

  @media ${device.md} {
    padding: 2rem 3.5rem 2rem 1rem;
  }

  @media ${device.sm} {
    padding: 2rem 3.5rem;
  }
`;

const CouponLeft = styled.div`
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  background: ${(p) => p.theme.background};
  position: absolute;
  left: -2rem;
  top: 50%;
  transform: translateY(-50%);

  @media ${device.lg} {
    top: 10rem;
  }

  @media ${device.md} {
    top: 50%;
  }

  @media ${device.sm} {
    top: 10rem;
  }
`;
const CouponRight = styled.div`
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  background: ${(p) => p.theme.background};
  position: absolute;
  right: -2rem;
  top: 50%;
  transform: translateY(-50%);

  @media ${device.lg} {
    top: 10rem;
  }

  @media ${device.md} {
    top: 50%;
  }

  @media ${device.sm} {
    top: 10rem;
  }
`;

export default function Coupon_(props) {

  
  const { t } = useTranslation();
  const { alerts, setAlerts } = useContext(AlertContext);
  const [couponInput, setCouponInput] = useState('')
  const [valid, setIsValid] = useState(false)
  const [loading, setIsLoading] = useState(true)
  const [defaultCoupon, setDefaultCoupon]  = useState('FREETRIAL15');
  const [couponInfo, setCouponInfo] = useState(false);
  const on_session_customer = props?.customer || false;
  const params = store.getState().params;

  const alertMessage = (message, status) => {
    setAlerts([
        ...alerts,
        {
            message: {
                text: message,
                status: status,
            },
        },
    ]);
  };

  const checkCouponIsValidOnClick =(promoCode) =>{
    coupon_validation(promoCode, params?.category, on_session_customer)
    .then(res => {
      console.log(res)
      if(res?.data?.code === 'S4000' || res?.data?.code === 'S4003' || res?.data?.code === 'S4002' || res?.data?.code === 'S4001') {
        setDefaultCoupon('')
        setCouponInput('')
        setIsValid(false)
        props?.setTrial(false)
        props?.setDiscount('')
        alertMessage('Not a valid coupon', 'Error' )

      }
      else if(res?.data?.code === 'S4001'){
        setDefaultCoupon('')
        setCouponInput('')
        setIsValid(false)
        props?.setTrial(false)
        props?.setDiscount('')
        alertMessage('You have already redeemed this promo code', 'Error' )
      }
      else{
        props?.setDiscount(res?.data)
        setIsValid(true)
        props?.setTrial(true)
        setCouponInfo(res?.data?.couponInfo)
        alertMessage('Coupon is valid', 'Success' )
      }
    })
  }

  const checkForValidOnPageLoad =(promoCode) =>{
    console.log('on_session_customer', on_session_customer)

    coupon_validation(promoCode, params?.category, on_session_customer)
    .then(res => {
      if(res?.data?.code === 'S4000' || res?.data?.code === 'S4003' || res?.data?.code === 'S4002' || res?.data?.code === 'S4001') {
        setDefaultCoupon('')
        setCouponInput('')
        setIsValid(false)
        props?.setTrial(false)
        props?.setDiscount('')
        setIsLoading(false);
      }
      else{
        setCouponInput('FREETRIAL15')
        props?.setDiscount(res?.data)
        setIsValid(true)
        props?.setTrial(true)
        setCouponInfo(res?.data?.couponInfo)
        alertMessage('Coupon is valid', 'Success' )
        setIsLoading(false);
      }
    })
  }
  
  const handleCouponInput = (e) =>{
    setDefaultCoupon('')
    props?.setDiscount('')
    setIsValid(false)
    props?.setTrial(false)
    setCouponInput(e.target.value)
  }
  const removeCoupon =  () =>{
    setDefaultCoupon('')
    setIsValid(false)
    props?.setTrial(false)
    props?.setDiscount('')
    setCouponInput('')
  }

  useEffect(()=>{
    console.log('USEEFFECT check for coupon', props?.customer)
    if(!couponInput && params?.category==='lois'){
      setCouponInput('')
      setDefaultCoupon('')
      checkForValidOnPageLoad('FREETRIAL15')
    }
    else {
      setDefaultCoupon('')
      setIsValid(false)
      props?.setTrial(false)
      props?.setDiscount('')
      setCouponInput('')
      setIsLoading(false)
    }
  },[])

  useEffect(()=>{
    if(!props?.trial){
      setDefaultCoupon('')
      setIsValid(false)
      props?.setTrial(false)
      setCouponInput('')
      setIsLoading(false);
    }
  },[])
  console.log('loading valid coupon', loading)
  if(loading) return <Main_Loader/>
  return (
    <Coupon>
    <Alert_ messages={alerts} /> 
      {/* <Builder_ title={t("Select a payment method")}>
        <Radio_ text={t("Stripe")} checked />
      </Builder_> */}
      <Builder_ title={t("If you have a coupon code add it here")}>
       <Input_
          placeholder={t("Coupon code")}
          list={couponInput && valid ? [couponInput] : []} //Max one code per checkout
          defaultListItem = {couponInput!==defaultCoupon ? defaultCoupon : ''}
          defaultValue = {defaultCoupon ? defaultCoupon : ''}
          value= {couponInput}
          onDelete={()=> removeCoupon()}
          onChange ={(e) => handleCouponInput(e)}
          icon_ListItem="Coupon"
          add={t("Submit")}
          onAdd={() => checkCouponIsValidOnClick(couponInput)}
        />
      </Builder_>
        {!loading && valid ? <CouponGraph>
        <CouponSissors>
            <h4>{t("Coupon applied successfully")}</h4>
            <h1>{couponInput && valid ? couponInput : defaultCoupon}</h1>
        </CouponSissors>
        <CouponPerks>
            <ul>
            <li>
                <IconSvg Icon="Bullet" />
                <p>{t(couponInfo?.couponText)}</p>
            </li>
            <li>
                <IconSvg Icon="Bullet" />
                <p>{t("Cancel any time")}</p>
            </li>
            <li>
                <IconSvg Icon="Bullet" />
                <p>{t("Access all Adsnap sources")}</p>
            </li>
            </ul>
        </CouponPerks>
        <CouponLeft></CouponLeft>
        <CouponRight></CouponRight>
        </CouponGraph> : null}
    </Coupon>
  )
}
