import axios from 'axios';
import { config } from "../../config"

const d_protocol = process.env.REACT_APP_DAILYPROFIT_BACKEND_PROTOCOL;
const d_url = process.env.REACT_APP_DAILYPROFIT_BACKEND_HOST;
const d_port = process.env.REACT_APP_DAILYPROFIT_BACKEND_PORT;
const dailyprofitURL = `${d_protocol}${d_url}${d_port}`


const getAmountFromQuest = (questId) =>{
    return new Promise((resolve, reject)=>{
        axios.get(`${config.DAILYPROFIT_BACKEND_URL}/quests/getMyQuest?questId=${questId}`)
        .then(res=>{
            resolve(res?.data)
        })
        .catch(()=>{
            resolve(false);
        })

    })
}

const getInfoFromQuest = (subId) =>{
    return new Promise((resolve, reject)=>{
        axios.get(`${config.DAILYPROFIT_BACKEND_URL}/quests/getMyQuest?subId=${subId}`)
        .then(res=>{
            resolve(res?.data)
        })
        .catch(()=>{
            resolve(false);
        })

    })
}

const getQuestsWithSubIds = (userId) =>{
    return new Promise((resolve, reject)=>{
        axios.get(`${config.DAILYPROFIT_BACKEND_URL}/quests/getMyQuestsWithSubs?userId=${userId}`)
        .then(res=>{
            resolve(res)
        })
        .catch(()=>{
            resolve(false);
        })

    })
}
export {
    getAmountFromQuest,
    getInfoFromQuest,
    getQuestsWithSubIds
};
