import {
    Builder_,
    Checkbox_,
    Dates_,
    Dropdown_,
    Main_,
    Radio_,
    Slider_,
    countries,
} from 'monica-alexandria';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { formatDate } from '../utils/formatDates';
import { useTranslation } from 'react-i18next';

const UserFilters = styled.div``;

export const Subscriber_Filters = (props) => {

    const [categoryOption, setCategoryOption] = useState(props.filters?.category);
    const {t} = useTranslation()

    //const [datesOption, setDatesOption] = useState(props.dates)

    const handleCategoryChange = (category) => {
        setCategoryOption(category)
    };
  

    const handleConversionDateOption = (selections) => {

        props.setDates({...props.dates, renderedConversionDates: selections , renderedLossDates: ''})
     
        if (selections.length === 2) {
            props.setDates(prevDates => ({
              ...prevDates,
              conversionDates: {
                ...prevDates.conversionDates,
                sinceDate: formatDate(selections[0]),
                untilDate: formatDate(selections[1]),
              },
              lossDates: {
                ...prevDates.lossDates,
                sinceDate: '',
                untilDate: '',
              },
            }));
          }
        
    }

    const handleLossDateOption = (selections) => {
        props.setDates({...props.dates, renderedLossDates: selections, renderedConversionDates:''})
     
        if (selections.length === 2) {
            props.setDates(prevDates => ({
              ...prevDates,
              lossDates: {
                ...prevDates.lossDates,
                sinceDate: formatDate(selections[0]),
                untilDate: formatDate(selections[1]),
              },
              conversionDates: {
                ...prevDates.conversionDates,
                sinceDate: '',
                untilDate: '',
              },
            }));
          }
    }


    useEffect(() => {
        props.setFilters({...props.filters, category: categoryOption})
    },[categoryOption])
 
    return (
        <UserFilters>   
            <Slider_ 
                title={t("Filters")} 
                text={t("Apply")}
                onClose = {() => props.toggleSlider(false)}
                onClick = {() => props.toggleSlider(false)}
            >
            <Builder_ title={t("Category")}>
                    <Radio_
                        text="Tailormade"
                        id="bond"
                        name = 'Category'
                        checked={categoryOption ==='bond'}
                        onChange={() => handleCategoryChange('bond')}
                    />
                    <Radio_ 
                        text="Adsnap"
                        id="lois"
                        name = 'Category'
                        checked={categoryOption === 'lois'}
                        onChange={() => handleCategoryChange('lois')}
                    />
                    <Radio_ 
                        text="Questad"
                        id="dailyprofit"
                        name = 'Category'
                        checked={categoryOption === 'dailyprofit'}
                        onChange={() => handleCategoryChange('dailyprofit')}
                    />
                    <Radio_ 
                        text="Mini Websites"
                        id="monica"
                        name = 'Category'
                        checked={categoryOption === 'monica'}
                        onChange={()=>handleCategoryChange('monica')}
                    />
            </Builder_>
            <Builder_ title={t("Subscriber conversion count by date and tool (all time = n)")}>
                <Dates_ onChange={handleConversionDateOption} value={props.dates.renderedConversionDates} selectRange={true} dateFullCellRender={(date) => date.date()} maxDate={new Date()} />
            </Builder_>
            <Builder_ title={t("Subscriber loss count by date and tool (all time = n)")}>
                <Dates_ onChange={handleLossDateOption} value={props.dates.renderedLossDates} selectRange={true} dateFullCellRender={(date) => date.date()} maxDate={new Date()} />
            </Builder_>
            </Slider_>
        </UserFilters>
    );
};
