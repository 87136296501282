import React, { useState, useEffect, useContext } from "react";
import mqtt from 'mqtt/dist/mqtt';
import store from '../../redux/store'

const websocketUrl = "ws:localhost:9001";
const user = store.getState().user?.user ? store.getState().user?.user : 'Guest'
const id = user === 'Guest' ? 'No auth': user?.sub;
const email = !user?.email ? 'No email': user?.email;

const logger = (action, type, user) => {


}

function getClient(errorHandler) {

  const client = mqtt.connect(websocketUrl);

  client.stream.on("error", (err) => {
    errorHandler(`Connection to ${websocketUrl} failed`);
    client.end();
  });

  logger('connected', 'Success')

  return client;
}

function subscribe(client, topic, user) {

    if (!user?.sub) return;
  
    const after_subscription_attempt = (err, granted) => {
      if (err) logger('failed to subscribe to topic '+ topic, 'Error', user)
      else logger('subscribed to topic '+ topic, 'Success', user)
    };

    return client.subscribe(topic, {qos: 2}, after_subscription_attempt);
}

function onMessage(client, user) {

  return new Promise((resolve,reject) => {
    if (!user?.sub) return;
 
     client.on("message", (topic, message, packet) => {
         resolve(message.toString())
     });
     
 
  })
}

function unsubscribe(client, topic) {

    logger('unsubscribed from topic '+ topic, 'Success')

    client.unsubscribe(topic);
}

function closeConnection(client) {

    logger('disconnected', 'Warning')

    client.end();
}

const mqttService = {
  getClient,
  subscribe,
  onMessage,
  unsubscribe,
  closeConnection,
};

export default mqttService;

