import { Builder_, Radio_, Steps_Actions } from "monica-alexandria";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { StepContext } from "../../../context/StepContext";

const ChoosePlanPer = styled.div``;

export default function Choose_Multiple(props) {
  const { setStep } = useContext(StepContext);
  const { t } = useTranslation();

  const i_want_the_plan_to_be_able_to_be_purchased_multiple_times = () => {
    props.setInterval({ ...props.model, multiPurchase: true });
  };

  const i_want_the_plan_to_be_able_to_be_purchased_one_time = () => {
    let tempModel = JSON.parse(JSON.stringify(props.model));
    if (tempModel.multiPurchase) delete tempModel.multiPurchase;
    props.setInterval(tempModel);
  };

  return (
    <ChoosePlanPer>
      <Builder_
        title={t("Will it be possible for your plan to be purchased multiple times by the same user? (REMOVE, this info is taken based on plan)")}
      >
        <Radio_
          id="Yes"
          text={t('Yes')}
          name="per"
          onChange={(e) =>
            i_want_the_plan_to_be_able_to_be_purchased_multiple_times()
          }
        />
        <Radio_
          id="No"
          text={t('No')}
          name="per"
          onChange={(e) =>
            i_want_the_plan_to_be_able_to_be_purchased_one_time()
          }
        />
        <Steps_Actions
          icon_Grey="Back"
          icon_Main="Next"
          onClick_Grey={() => setStep(3)}
          onClick_Main={() => setStep(5)}
          text_Grey={t("Back")}
          text_Main={t("Next")}
        />
      </Builder_>
    </ChoosePlanPer>
  );
}
