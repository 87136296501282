import React from 'react'
import { useTranslation } from 'react-i18next'
import { Single_ } from 'monica-alexandria'


export default function INVALID() {

  const {t} = useTranslation();

  return (
    <Single_
    backgroundImage="https://genius1071.friktoriaservers.net/doitforme/morning/app/griphook/NoAccess.svg"
    title={t("Invalid request")}
    subtitle={t("There was a problem with your request")}
    textMain={t("Back to my panel")}
    onClickMain={() => {}}
  />
  )
}
