import { Toggle_ } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Annual = styled.div`
    display: flex;
    gap: 1rem;
    margin: 2rem 0;
    align-items: center;

    .Toggle{

        div{
            background: var(--main) !important;
        }
    }
`

export default function Annual_(props) {

    const {t} = useTranslation()

  return (
    <Annual>
        <h5>{t("Paid monthly")}</h5>
        <Toggle_ active={props.annual} onClick={props.onAnnual}/>
        <h5>{t("Paid annually")}:</h5>
        <p>{t("Save 10% when compared with monthly plan")}</p>
    </Annual>
  )
}
