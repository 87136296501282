import { Actions_, Focus_, Grey_, Section_Title } from "monica-alexandria";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { prettifyPrice } from "../../../helpers/prettifyFunctions";
import store from "../../../redux/store";

const PaymentSummary = styled.div``;

const PaymentSummaryList = styled.ul`
  padding: var(--normalPads);
`;

const PaymentSummaryListItem = styled.li`
  display: flex;
  gap: 1rem;
  align-items: baseline;
  justify-content: space-between;
`;

const PaymentSummarySeparator = styled.li`
  grid-column-start: 1;
  grid-column-end: 3;
  width: 100%;
  margin: 1rem auto;
  height: 2px;
  background: ${(p) => p.theme.low};
`;

const PaymentSummaryAfter = styled.div`
  display: flex;
  justify-content: flex-end;

  h6{
    color: var(--warning);
  }
`;

export default function Payment_Summary(props) {
  const { t } = useTranslation();
  const cart = store.getState().cart[0]

  function prettifyCurrency(currency) {
    if (currency == "eur") return "€";
    else return currency;
  }
  return (
    <PaymentSummary>
      <PaymentSummaryList>
        <PaymentSummarySeparator></PaymentSummarySeparator>
        <PaymentSummaryListItem>
          <h5>{t("Original price")}:</h5>
          <p>{`${prettifyCurrency(props?.invoice?.currency)}${prettifyPrice(props?.invoice?.subTotal)}/${t(cart?.interval)}`}</p>
        </PaymentSummaryListItem>
        {props?.trial ? <PaymentSummaryListItem>
          <h5>{t("Coupon discount")}:</h5>
          <p>{`${prettifyCurrency(props?.invoice?.currency)}${prettifyPrice((props?.invoice?.subTotal * props?.invoice?.discount )/100)}`}</p>
        </PaymentSummaryListItem> : null}
        <PaymentSummaryListItem>
          <h5>{t("Tax")}:</h5>
          <p>{`${prettifyCurrency(props?.invoice?.currency)}${prettifyPrice(props?.invoice?.tax[0]?.amount)}/${t(cart?.interval)}`}</p>
        </PaymentSummaryListItem>
        <PaymentSummarySeparator></PaymentSummarySeparator>
        <PaymentSummaryListItem>
          <h3>{t("Total")}:</h3>
          <h4>{`${prettifyCurrency(props?.invoice?.currency)}${prettifyPrice(props?.invoice?.tax[0]?.taxable_amount +props?.invoice?.tax[0]?.amount)}/${t(cart?.interval)}`}</h4>
        </PaymentSummaryListItem>
        {props.trial ?
          <PaymentSummaryAfter>
            <h6>*{t("Charge will start in")} {props?.trialDays} {t("days")}</h6>
          </PaymentSummaryAfter>
        : 
          null
        }
      </PaymentSummaryList>
    </PaymentSummary>
  );
}
