import {
  PageAlwaysVisible,
  PageTitle,
  Section_Title,
  Single_,
  ThemeContext,
  logo,
  Main_Loader,
  PageSixty,
  Confirmation_,
  Grey_Link,
  Alert_,
  AlertContext
} from "monica-alexandria";
import React, {useContext, useEffect, useState } from "react";
import { prettifyDates } from "../../helpers/prettifiers";
import store from "../../redux/store";
import { getTransactions } from "../../services/api/Transactions";
import { create_portal_session, get_my_subscriptions } from "../../services/api/Mocks";
import i18n from "../../localization/i18next";
import { useTranslation } from "react-i18next";
import { prettifyPrice } from "../../helpers/prettifyFunctions";
import currencies from '../../data/currencies.json';
import { Helmet } from "react-helmet";
import Subscription_Plan from "../../components/subscription/Subscription_Plan";
import Subscription_History_Block from "../../components/subscription/Subscription_History_Block";
import styled from "styled-components";
import { cancelSubscription, renewSubscription } from "../../services/api/Subscriptions";
import { useNavigate } from "react-router-dom";

const PocketAgencyPlan = styled.div``;

export default function POCKET_AGENCY_PLAN() {
    const protocol = process.env.REACT_APP_GRIPHOOK_FRONTEND_PROTOCOL;
    const url = process.env.REACT_APP_GRIPHOOK_FRONTEND_HOST;
    const port = process.env.REACT_APP_GRIPHOOK_FRONTEND_PORT;
    const griphookFrontURL = `${protocol}${url}${port}`;

    const user = store.getState().user.user;
    const userId = user.sub;
    const { t } = useTranslation();
    const { theme } = useContext(ThemeContext);
    const navigate = useNavigate();
    const { alerts, setAlerts } = useContext(AlertContext);

    const lang = i18n.language ? i18n.language : 'en';
    const [isLoading, setIsLoading] = useState(false);
    // Based on the last transaction
    const [transactions, setTransactions] = useState([])

    const redirectURL = `${window.location.origin}${window.location.pathname}?name=PocketAgency&category=bond`;
    const [ initialLoading, setInitialLoading ] = useState(true);

    const [plan, setPlan] = useState(null);
    const [cancelConfirm, setCancelConfirm] = useState(false);
    const [renewConfirm, setRenewConfirm] = useState(false);

    const totalAmount = (tools) => {
      const total = tools?.map(t => t?.amount);  
      if (total) {
        const sum = total.reduce((accumulator, currentValue) => {
          if (typeof currentValue === 'number') {
            return accumulator + currentValue;
          }
          return accumulator;
        }, 0);
        return sum;
      }
    };

    const alertMessage = (message, status) => {
      setAlerts([
        ...alerts,
        {
          message: {
            text: t(message),
            status: status,
          },
        },
      ]);
      setTimeout(() => {
        setAlerts([]);
      }, 8000);
    };

    const fetchSubscriptions = () => {
      get_my_subscriptions(userId)
      .then(data => {
        
        if (data?.data?.length > 0) {
          const subPocket = data?.data.find(subscription => subscription.category === "bond");
          console.log(subPocket);
          
          const activeSub = subPocket?.tools?.find(sub => sub.active)
          console.log(activeSub);

          if(activeSub) setPlan(activeSub);
          else setPlan(subPocket?.tools[subPocket?.tools?.length - 1])
        } 
        else {
          setPlan({})
          // No subscriptions found for the user, set as an empty object
        }
      })
      .catch(err => {});
    };
    
    const go_to_edit_subscription = (customer) => {
  
      let payload = {
        customer,
        lang:lang,
        userId,
        redirectURL
      }
  
      create_portal_session(payload)
        .then(data => {
          if (data?.data?.url) {
            setIsLoading(false)
            window.location.assign(data?.data?.url)
          }
          else isLoading(true);
        })
        .catch(err => {})
    }
    
    const cancel_subscription = () => {
      console.log(plan);
      
      cancelSubscription(plan.subscription)
      .then(res => {
        console.log(res);
        if(res.data.code === "CSS2000") {
          setPlan((prevState) => ({
            ...prevState,
            cancel_at_period_end: true  
          }));
          const message = (t("Your plan was canceled"));
          const status = "Success";
          alertMessage(message, status);
        }
      })
      .catch(err => console.log(err))
    }

    const renew_subscription = () => {
      console.log(plan);

      renewSubscription(plan.subscription)
      .then(res => {
        console.log(res);
        if(res.data.code === "UCS2000") {
          setPlan((prevState) => ({
            ...prevState, 
            cancel_at_period_end: false  
          }));
          const message = (t("Your plan renewed"));
          const status = "Success";
          alertMessage(message, status);
        }    
      })
      .catch(err => console.log(err))
    }

    useEffect(() => {
        fetchSubscriptions();
    }, []);


    useEffect(()=>{
      if(plan?.customer){
        getTransactions(plan?.customer)
          .then((res) => {
            console.log('res', res)
            setTransactions(res?.data);
            setInitialLoading(false)
          })
          .catch((err) => {console.log(err)});
      }
    }, [plan])
      console.log('plan', plan)
      console.log('trans', transactions)
  
      
    return (
      <PocketAgencyPlan>
        <Helmet>
          <title>{`Doitforme | Pocket Agency subscription`}</title>
        </Helmet>
        <Alert_/>

          { initialLoading ? <Main_Loader /> : 
          <PageSixty>
            <PageAlwaysVisible>
              <PageTitle>
                <Grey_Link noPadding iconLeft="Back" onClick={() => navigate(-1)} />
                <h1>{"Pocket Agency"} {t("subscription")}</h1>
              </PageTitle>
              {cancelConfirm && (
                <Confirmation_
                  message={t("Are you sure you want to cancel your subscription?")}
                  yes={t("Yes")}
                  onYes={() => {cancel_subscription(); setCancelConfirm(false)}}
                  onNo={() => setCancelConfirm(false)}
                  no={t("No")}
                />
              )}
              {renewConfirm && (
                <Confirmation_
                  message={t("Are you sure you want to renew your subscription?")}
                  yes={t("Yes")}
                  onYes={() => {renew_subscription(); setRenewConfirm(false)}}
                  onNo={() => setRenewConfirm(false)}
                  no={t("No")}
                />
              )}
              <Subscription_Plan
                title={t("Tool")}
                category="bond"
                currentPlan= {"Pocket Agency"}
                img={logo["bond"][theme]}
                onCancel={()=> setCancelConfirm(true)}
                onRenew={()=> setRenewConfirm(true)}
                onEditCard={()=> { go_to_edit_subscription(plan?.customer) } }
                currency= {currencies[plan?.currency.toUpperCase()]?.symbol}
                price= {prettifyPrice(plan?.amount)}
                isActive={plan.active}
                taxBehavior={plan?.taxBehavior}
                interval={plan?.interval}
                interval_count={plan?.interval_count}
                cancel_at_period_end={plan?.cancel_at_period_end}
                trial={ plan.status == 'trialing' ? true : false}
                failed={ plan.status == 'past_due' ? true : false}
                daysLeft={Math.ceil((plan?.current_period_end * 1000 - Date.now()) / (1000 * 60 * 60 * 24))}
                nextPayment={(!plan?.active || plan?.cancel_at_period_end) ? (t("Auto renewal canceled")) : `${prettifyDates(plan?.current_period_end, true, lang)}`} //auto renewal canceled
                cardNo= {transactions[transactions?.length - 1]?.customer?.invoice_settings?.default_payment_method?.card?.last4 || 'no card'}

              />

            </PageAlwaysVisible>
            
            <PageAlwaysVisible>
              <Section_Title text={t('Payment History')} />
                {transactions?.length > 0 &&
                  transactions?.map((invoice, i) => {
                    return (
                      <Subscription_History_Block
                          key={i}
                          currency={currencies[invoice?.currency.toUpperCase()]?.symbol}
                          price={prettifyPrice(invoice?.total)}
                          status={invoice?.status}
                          date={prettifyDates(invoice?.created, true, lang)}
                          invoicePdf={invoice.invoice_pdf}
                      />
                    );
                  })}
            </PageAlwaysVisible>
          </PageSixty> }
      </PocketAgencyPlan>
    );
  }

  
  