import {
  Actions_,
  Builder_,
  CardsSixty,
  Main_,
  Main_Plain,
  On_Gradient_Card,
  Radio_,
  Red_,
  Section_Title,
  Stat_,
  ThemeContext,
  Toggle_Stat,
  device,
  logo,
} from "monica-alexandria";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { prettifyPrice } from "../../../helpers/prettifyFunctions";
import { CartContext } from "../../../context/CartContext";
import { handleCart } from "../utils";

const CheckoutItems = styled.div``;

const CheckoutItem = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(10rem, auto);
  align-items: center;
  grid-gap: 1rem;
  margin-bottom: 1rem;
`;

const CheckoutItemsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 2rem;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  h1,h6{
    white-space: nowrap;
  }

  @media ${device.lg} {
    grid-template-columns: 1fr;
  }

  @media ${device.md} {
    grid-template-columns: 1fr auto;
  }

  @media ${device.sm} {
    grid-template-columns: 1fr;
  }
`;

const CheckoutItemsPrice = styled.div`
  h5{white-space: nowrap}

  @media ${device.lg} {
    justify-self: flex-end;
  }

  @media ${device.md} {
    justify-self: flex-end;
  }

  @media ${device.sm} {
    justify-self: flex-end;
  }
`;

const CheckoutItemSources = styled.div``;

const CheckoutItemsTabs = styled.div``;

const CheckoutItemsRowPrice = styled.h5`
  white-space: nowrap;
`

const CheckoutItemsTotal = styled.div`
  display: flex;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 2px solid var(--greyDark);
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`

export default function Checkout_Items(props) {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const { cart, setCart } = useContext(CartContext);
  const [toggles, setToggles] = useState([]);

  const handleRadioChange = (event) => {
    props?.setSelectedOption(event.target.value);
  };

  const handleToggleClick = (index, source) => {
    const newToggles = [...toggles];
    newToggles[index] = !newToggles[index];
    setToggles(newToggles);

    if (newToggles[index]) {
      setCart(prevCart => [...prevCart, source]);
    } else {
      const updatedCart = cart.filter(item => item.priceId !== source.priceId);
      setCart(updatedCart);
    }
  };
  

  function prettifyCurrency(currency) {
    //TODO : change to currency
    if (currency == "eur") return "€";
    else return currency;
  }

  useEffect(() => {
    // Check if props.sources has exactly one item
    if (props?.sources && props?.sources?.length === 1) {
      // Set toggle state to true and prevent it from being untoggled
      setToggles([true]);
      if (!cart.some(item => item.priceId === props?.sources[0]?.priceId)) {
        setCart(prevCart => [...prevCart, props.sources[0]]);
      }
      // Add the single source to the cart if not already added
    } else {
      // For any other case, initialize toggles based on cart items
      const newToggles = props?.sources?.map(source => cart.some(item => item.priceId === source.priceId));
      setToggles(newToggles);
    }
  }, [cart, props.sources]);

 console.log('props.sources[0]', props?.sources[0])
 console.log('cart', cart)

 
    return (
    <CheckoutItems>
        {props?.queryParams.category==='bond' ? <CheckoutItemsTabs>
       <Builder_ title={t("Save 10% when compared with monthly plan")}>
          <Radio_ 
            text={t("Charge me annually")}
            value="year"
            checked={props?.selectedOption === 'year'}
            onChange={handleRadioChange} 
          />
        </Builder_> 
        <Builder_ title={t("Most flexible option. Billed every month.")}>
          <Radio_ 
          text={t("Charge me monthly")} 
          value="month"
          checked={props?.selectedOption === 'month'}
          onChange={handleRadioChange} 
          />
        </Builder_>
      </CheckoutItemsTabs> : null}
      <Builder_>
        <CheckoutItemsRow>
        {props?.defaultItem ? <Stat_
          title={t("Subscription plan")}
          text={props?.defaultItem?.name}
          imgBehave="Contain"
          img={ cart[0]?.category ? logo[cart[0]?.category][theme] : "NotFound"}
        /> : null}
          <CheckoutItemsPrice>
            <h5>{`${prettifyCurrency(props?.defaultItem?.currency)} ${prettifyPrice(props?.defaultItem?.amount, props?.defaultItem?.interval)}/${t("month")}`}</h5>
          </CheckoutItemsPrice>
        </CheckoutItemsRow>
      </Builder_>
      {props?.sources && props?.sources.length>0 ? (
        <CheckoutItemSources>
          {props?.queryParams.category==='bond' ?
          <Builder_ title={t("Add sources")}>
            {props?.sources?.map((source, i) => (
              <CheckoutItemsRow key={i} id={i}>
                <CheckoutItem>
                <Toggle_Stat active={toggles[i]} onClick={() => handleToggleClick(i, source) }/>
                  <h5>{source.name}</h5>
                </CheckoutItem>
                <CheckoutItemsRowPrice>{`${prettifyCurrency(source?.currency)}${prettifyPrice(source?.amount)}/${source?.interval}`}</CheckoutItemsRowPrice>
              </CheckoutItemsRow>
            ))}
          </Builder_> :props?.queryParams.category==='lois' ?
          <Builder_ title={t("Integrations")}>
            <CardsSixty>
            {props?.sources?.map((source, i) => (
              // <CheckoutItemsRow key={i} id={i}>
              //   <CheckoutItem>
              //   <Toggle_Stat active={toggles[i]} onClick={() => handleToggleClick(i, source) }/>
              //     <h5>{source.name}</h5>
              //   </CheckoutItem>
              //   <CheckoutItemsRowPrice>{`${prettifyCurrency(source?.currency)}${prettifyPrice(source?.amount)}/${source?.interval}`}</CheckoutItemsRowPrice>
              // </CheckoutItemsRow>
              
              <On_Gradient_Card key={i} id={i}
                firstColor="var(--metaOne)" secondColor="var(--metaTwo)" title={source.name} img="https://genius1071.friktoriaservers.net/doitforme/morning/app/adsnap/sources/MetaLogo.png"
              >
                <h5>+ {`${prettifyCurrency(source?.currency)}${prettifyPrice(source?.amount)}/${source?.interval}`}</h5>
                {/* <Toggle_Stat active={toggles[i]} onClick={() => handleToggleClick(i, source) }/> */}
              </On_Gradient_Card>
            ))}
              <On_Gradient_Card 
                firstColor="#ff3e30" secondColor="var(--white)" title="Google Ads" img="https://genius1071.friktoriaservers.net/doitforme/morning/app/adsnap/sources/GoogleLogo.png"
              >
                <h5>{t("Coming next")}</h5>
              </On_Gradient_Card>
          </CardsSixty>
            <CheckoutItemsTotal>
              <h3>{t("Total")}:</h3>
              <h3>€ 9,90/month</h3>
            </CheckoutItemsTotal>
        </Builder_>
        : 
          null
        }
        </CheckoutItemSources>
      ) : null}
    </CheckoutItems>
  );
}
