import {
  Builder_,
  CardsSixty,
  On_Click_Card,
  Radio_,
  Slider_,
  Steps_Actions,
  ThemeContext,
  logo,
} from "monica-alexandria";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { StepContext } from "../../../context/StepContext";
import { plan_exists } from "../../../services/api/Mocks";
import { prettifyPrice } from "../../../helpers/prettifyFunctions";

const ChoosePlanPer = styled.div``;

export default function Choose_Trial(props) {
  const { theme } = useContext(ThemeContext);
  const { setStep } = useContext(StepContext);
  const { t } = useTranslation();
  const [slider, setSlider] = useState(false)
  const [similar, setSimilar] = useState({})
  const [selectedValue, setSelectedValue] = useState(props?.model?.trial ? 'yes' : 'no')

  function prettifyCurrency(currency) {
    if (currency == "eur") return "€";
    else return currency;
  }

  const i_want_free_trial = (e) => {
    setSelectedValue(e.target.value)
    props.setInterval({ ...props.model, trial: true });
  };

  const i_do_not_want_free_trial = (e) => {
    setSelectedValue(e.target.value)
    let tempModel = JSON.parse(JSON.stringify(props.model));
    if (tempModel.trial) delete tempModel.trial;
    props.setInterval(tempModel);
  };
  const showSlider = (model) =>{
    plan_exists(model)
    .then(res => {
      setSlider(res?.data?.data?.exists)
      setSimilar(res?.data?.data?.plan)
      if(!res?.data?.data?.plan) setStep(5);
    })
    .catch(err => {
        setSlider(false);
        setSimilar([]);
      })
  }
  console.log('similar', similar)

  return (
    <ChoosePlanPer>
      {slider ? <Slider_ title={t("Caution")} text={t("I wish to continue")} icon="Next" onClick={()=> setStep(5)}>
        <Builder_
          title={t('One or more plans with simillar information already exist. Please take a look at them before creating a new one that offers the same experience as others.')}
        ></Builder_>
        <CardsSixty>
          <On_Click_Card 
            img={logo.bond[theme]}
            imgBehave="Contain"
            title={`${prettifyPrice(similar?.amount)}${prettifyCurrency(similar?.currency)}/${similar?.interval}`}
            subtitle={similar?.name}
          />
        </CardsSixty>
      </Slider_> : null}
      <Builder_ title={t("Would you like to add a 15-day free trial? STEP_4")}>
        <Radio_
          id="Yes"
          text={t('Yes')}
          name="per"
          value='yes'
          checked={selectedValue=='yes'}
          onChange={(e) => i_want_free_trial(e)}
        />
        <Radio_
          id="No"
          text={t('No')}
          name="per"
          value='no'
          checked={selectedValue=='no'}
          onChange={(e) => i_do_not_want_free_trial(e)}
        />
        <Steps_Actions
          icon_Grey="Back"
          icon_Main="Next"
          onClick_Grey={() => setStep(3)}
          onClick_Main={() => showSlider(props.model)}
          text_Grey={t("Back")}
          text_Main={t("Next")}
        />
      </Builder_>
    </ChoosePlanPer>
  );
}
