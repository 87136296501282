import { create_customer } from "../../services/api/Subscriptions";
import i18n from "../../localization/i18next";
import store from "../../redux/store";
const lang = i18n.language ? i18n.language : "el";

export function handleCheckoutSession(navigate, billingFields, user, category, t, alertMessage){
    let alreadyCustomer = window.sessionStorage.getItem("customer");
    if(alreadyCustomer==='undefined'){
        create_customer(billingFields, user?.username, user?.sub, category)
        .then(res => {
            if(res?.code==='SCC4000') {
                alertMessage(t(`Something went wrong. Check you information and try again.`), 'Error');
            }
            else{
                window.sessionStorage.setItem("customer", res?.customer);
                window.sessionStorage.setItem("billing", JSON.stringify(billingFields));
                navigate(`/${lang}/payment`)
            }
        })
        .catch(err => console.log(err))
    }
    else return navigate(`/${lang}/payment`) 
}

export function handleCart(cart, setCart, item) {
    // Retrieve cart from sessionStorage if available
    const storedCart = store.getState().cart;
    if (storedCart) {
      setCart(storedCart);
    } else {
      setCart([...cart, item]); // Initialize cart with default item
    }
  }
  
  export function validateFields(alertMessage, billingFields, t) {

    let allFieldsFilled = true; // Initialize a flag variable to true

    if (Object.keys(billingFields).length !== 0) {
        for (const key in billingFields) {
            if (billingFields.hasOwnProperty(key)) {
                const value = billingFields[key];

                if (value === "" || value === null) {
                    alertMessage(t(`Please fill in all the fields`), 'Error');
                    allFieldsFilled = false; // Set the flag to false if an empty field is found
                }
            }
        }
    } else {
        alertMessage(t(`Please fill in all the fields`), 'Error');
        allFieldsFilled = false; // Set the flag to false if there are no fields
    }

    return allFieldsFilled; // Return the flag value
}


export function validateCart(alertMessage, cart, priceId){
    // const baseItem = cart.find(item => item.property === 'base');
    // if((cart.length >1 && baseItem) || cart[0]?.category!=='lois') return true;
    // else {
    //     alertMessage("Please select a source", "Error")
    //     return false;
    // }
    return true;
}

export const validatePhone = (phone, t, region) => {
    let helper = [];
    let success = []
    if (!/^69/.test(phone)) {
      helper.push(t("Starts with 69"));
    }else{
      success.push(t("Starts with 69"));
    }
    if (!/^\d{10}$/.test(phone)) {
      helper.push(t("10 numbers"));
    }else{
      success.push(t("10 numbers"));
    }
    if (!/^\d+$/.test(phone)) {
      helper.push(t("Only numbers"));
    }else{
      success.push(t("Only numbers"));
    }
    return({
        helper,
        success
    })
}