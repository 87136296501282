import { Actions_, AlertContext, Builder_, Checkbox_, Focus_, Form, Input_, Main_Loader } from 'monica-alexandria'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { handleCheckoutSession, validateCart } from '../../pages/checkoutDetails/utils'
import { CartContext } from '../../context/CartContext'
import { useNavigate } from 'react-router-dom'
import store from '../../redux/store'
import { create_customer } from '../../services/api/Subscriptions'
import i18n from "../../localization/i18next";
import ToCheckout from '../../assets/billing/ToCheckout.svg'

const Billing = styled.div`

    .Illustation{
        max-width: 80%;
        margin: 0 auto;
        display: block;
        margin-bottom: 4rem;
    }

    button{
        width: 100%;
        justify-content: center;
    }
`

export default function Billing_(props) {

    const {t} = useTranslation()    
    const cart  = useContext(CartContext);
    const { alerts, setAlerts } = useContext(AlertContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const lang = i18n.language ? i18n.language : "el";
    const user = store.getState().user.user;
    const currentCart = cart.length>1 ? cart : store.getState().cart
      const alertMessage = (message, status) => {
        setAlerts([
            ...alerts,
            {
                message: {
                    text: message,
                    status: status,
                },
            },
        ]);
      };
      
      const createCustomer = () =>{
        setLoading(true)
        let alreadyCustomer = window.sessionStorage.getItem("customer");
        if(alreadyCustomer==='undefined'){
            create_customer(props?.billingFields, user?.username, user?.sub, currentCart[0]?.category)
            .then(res => {
                if(res?.code==='SCC4000') {
                    alertMessage(t(`Something went wrong. Check you information and try again.`), 'Error');
                    setLoading(false)
                }
                else{
                    window.sessionStorage.setItem("customer", res?.customer);
                    window.sessionStorage.setItem("billing", JSON.stringify(props?.billingFields));
                    window.sessionStorage.setItem("coupon", JSON.stringify(props?.discount));
                    window.sessionStorage.setItem("trial", props?.trial);
                    setLoading(false)
                    navigate(`/${lang}/adsnap-checkout-v3`)
                }
            })
            .catch(err => console.log(err))
        }
        else {
            window.sessionStorage.setItem("coupon", JSON.stringify(props?.discount));
            window.sessionStorage.setItem("trial", props?.trial);
            setLoading(false)
            return navigate(`/${lang}/adsnap-checkout-v3`)     
        }
    }
    
  if (loading) return <Main_Loader/>
  return (
    <Billing>
        <Form>
            <img className='Illustation' src={ToCheckout} />
            <Actions_ end>
                <Focus_ text={t("Procced to checkout")} iconRight="Next" size="large" onClick={() => createCustomer()} />
            </Actions_>
        </Form>
    </Billing>
  )
}
