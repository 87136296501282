import Keycloak from 'keycloak-js'
 
// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'

let keycloak;
if (process.env.REACT_APP_SERVER === 'LOCAL') {
    keycloak = new Keycloak('/keycloak.json')
}
else if (process.env.REACT_APP_SERVER === 'STAGING') { 
    keycloak= new Keycloak('/keycloakStaging.json')
}
else if (process.env.REACT_APP_SERVER === 'PRODUCTION') {
    keycloak = new Keycloak('/keycloakProduction.json')
}

export default keycloak